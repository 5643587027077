<template>
  <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
          <h2 class="text-lg font-medium mr-auto">Mix Packet Receive</h2>
          <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

          </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="grid grid-cols-12 mt-5">
          <div class="intro-y col-span-12 lg:col-span-12">
              <!-- BEGIN: Input -->
              <div class="intro-y box">

                  <div class="p-5 border-b">
                      <div class="grid grid-cols-12 gap-x-5">
                          <div class="col-span-12 sm:col-span-6 mt-1">

                              <div v-if="rowData.value.length == 0">
                                  <label for="scan_lot_no" class="form-label">Select Process</label>
                                  <TomSelect
                                    v-model="form_data.process_id"
                                      :options="{
                                        placeholder: 'Select Process'
                                      }"
                                      class="w-full m-auto"
                                      @change="get_kapan_list"
                                  >
                                  <option value="0">Select Process</option>
                                  <option v-for="item in screenData.process_list" :key="item.id" :value="item.id">{{item.process_name}}</option>
                                  </TomSelect>
                              </div>
                              <div v-if="rowData.value.length > 0">
                                  <label for="scan_lot_no" class="form-label">Select Process</label>
                                  <TomSelect
                                    v-model="form_data.process_id"
                                      :options="{
                                        placeholder: 'Select Process'
                                      }"
                                      class="w-full m-auto"
                                      disabled
                                  >
                                  <option value="0">Select Process</option>
                                  <option v-for="item in screenData.process_list" :key="item.id" :value="item.id">{{item.process_name}}</option>
                                  </TomSelect>
                              </div>

                              <div class="grid grid-cols-12 gap-x-5">
                                <div class="col-span-6 mt-1">

                                  <div>
                                      <label for="scan_lot_no" class="form-label">Shape</label><label class="text-theme-6 mt-2 ml-2" v-if="screenData.errors.shape_id">
                                      ( {{ screenData.errors.shape_id[0] }} )
                                  </label>
                                      <TomSelect
                                        v-model="form_data.shape_id"
                                          :options="{
                                            placeholder: 'Select Shape'
                                          }"
                                          class="w-full m-auto"
                                      >
                                      <option value="0">Select Shape</option>
                                      <option v-for="item in screenData.shape_items" :key="item.id" :value="item.id">{{item.shape_name}}</option>
                                      </TomSelect>
                                  </div>

                                </div>
                                <div class="col-span-6 mt-1">

                                  <div>
                                    <label for="scan_lot_no" class="form-label">Color</label><label class="text-theme-6 mt-2 ml-2" v-if="screenData.errors.color_id">
                                      ( {{ screenData.errors.color_id[0] }} )
                                  </label>
                                    <TomSelect
                                      v-model="form_data.color_id"
                                        :options="{
                                          placeholder: 'Select Color'
                                        }"
                                        class="w-full m-auto"
                                    >
                                    <option value="0">Select Color</option>
                                    <option v-for="item in screenData.color_items" :key="item.id" :value="item.id">{{item.color_name}}</option>
                                    </TomSelect>
                                  </div>

                                </div>
                                <div class="col-span-6 mt-1">

                                  <div>
                                    <label for="scan_lot_no" class="form-label">Purity</label><label class="text-theme-6 mt-2 ml-2" v-if="screenData.errors.purity_id">
                                      ( {{ screenData.errors.purity_id[0] }} )
                                  </label>
                                    <TomSelect
                                      v-model="form_data.purity_id"
                                        :options="{
                                          placeholder: 'Select Purity'
                                        }"
                                        class="w-full m-auto"
                                    >
                                    <option value="0">Select Purity</option>
                                    <option v-for="item in screenData.purity_items" :key="item.id" :value="item.id">{{item.purity_name}}</option>
                                    </TomSelect>
                                  </div>

                                </div>
                                <div class="col-span-6 mt-1">

                                  <div>
                                    <label for="scan_lot_no" class="form-label">Size</label><label class="text-theme-6 mt-2 ml-2" v-if="screenData.errors.size_id">
                                      ( {{ screenData.errors.size_id[0] }} )
                                  </label>
                                    <TomSelect
                                      v-model="form_data.size_id"
                                        :options="{
                                          placeholder: 'Select Purity'
                                        }"
                                        class="w-full m-auto"
                                    >
                                    <option value="0">Select Size</option>
                                    <option v-for="item in screenData.charni_arr" :key="item.id" :value="item.id">{{item.charni_name}}</option>
                                    </TomSelect>
                                  </div>

                                </div>
                                <div class="col-span-4 mt-1">

                                  <div>
                                    <label for="scan_lot_no" class="form-label">Cut</label><label class="text-theme-6 mt-2 ml-2" v-if="screenData.errors.cut_id">
                                      ( {{ screenData.errors.cut_id[0] }} )
                                  </label>
                                    <TomSelect
                                      v-model="form_data.cut_id"
                                        :options="{
                                          placeholder: 'Select Cut'
                                        }"
                                        class="w-full m-auto"
                                    >
                                    <option value="0">Select Cut</option>
                                    <option v-for="item in screenData.cut_items" :key="item.id" :value="item.id">{{item.cut_name}}</option>
                                    </TomSelect>
                                  </div>

                                </div>
                                <div class="col-span-4 mt-1">

                                  <div>
                                    <label for="scan_lot_no" class="form-label">Polish</label><label class="text-theme-6 mt-2 ml-2" v-if="screenData.errors.polish_id">
                                      ( {{ screenData.errors.polish_id[0] }} )
                                  </label>
                                    <TomSelect
                                      v-model="form_data.polish_id"
                                        :options="{
                                          placeholder: 'Select Polish'
                                        }"
                                        class="w-full m-auto"
                                    >
                                    <option value="0">Select Polish</option>
                                    <option v-for="item in screenData.polish_items" :key="item.id" :value="item.id">{{item.polish_name}}</option>
                                    </TomSelect>
                                  </div>

                                </div>
                                <div class="col-span-4 mt-1">

                                  <div>
                                    <label for="scan_lot_no" class="form-label">Symmetry</label><label class="text-theme-6 mt-2 ml-2" v-if="screenData.errors.symmetry_id">
                                      ( {{ screenData.errors.symmetry_id[0] }} )
                                  </label>
                                    <TomSelect
                                      v-model="form_data.symmetry_id"
                                        :options="{
                                          placeholder: 'Select Symmetry'
                                        }"
                                        class="w-full m-auto"
                                    >
                                    <option value="0">Select Symmetry</option>
                                    <option v-for="item in screenData.symmetry_items" :key="item.id" :value="item.id">{{item.symmetry_name}}</option>
                                    </TomSelect>
                                  </div>

                                </div>
                              </div>


                          </div>
                          <div class="col-span-12 sm:col-span-6 mt-1">

                            <div v-if="rowData.value.length == 0">
                                  <label for="scan_lot_no" class="form-label">Select Kapan</label>
                                  <TomSelect
                                    v-model="form_data.kapan_id"
                                      :options="{
                                        placeholder: 'Select Kapan'
                                      }"
                                      class="w-full m-auto"
                                  >
                                  <option value="0">Select Kapan</option>
                                  <option v-for="item in screenData.kapan_list" :key="item.lot_id" :value="item.lot_id">{{item.lot_no}} ({{item.voucher_no}})</option>
                                  </TomSelect>
                              </div>
                            <div v-if="rowData.value.length > 0">
                                  <label for="scan_lot_no" class="form-label">Select Kapan</label>
                                  <TomSelect
                                    v-model="form_data.kapan_id"
                                      :options="{
                                        placeholder: 'Select Kapan'
                                      }"
                                      class="w-full m-auto"
                                      disabled
                                  >
                                  <option value="0">Select Kapan</option>
                                  <option v-for="item in screenData.kapan_list" :key="item.lot_id" :value="item.lot_id">{{item.lot_no}} ({{item.voucher_no}})</option>
                                  </TomSelect>
                              </div>
                              <div class="mt-1">
                                  <label for="scan_lot_no" class="form-label">Scan Lot No</label>
                                  <div class="input-group">

                                      <input id="scan_lot_no" type="text" tabindex="1" placeholder="Scan Barcode" v-model="screenData.scan_lot_no" :class="{'form-control':true,'loading':screenData.scanload}" autocomplete="off" @change="scanAndAddPack" />

                                      <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                                          <SearchIcon class="w-5 h-5 mr-2" /> Search
                                      </button>
                                  </div>
                              </div>
                              <div class="mt-1">
                                <label for="new_receive_weight" class="form-label">New Weight(Ct)</label><label class="text-theme-6 mt-2 ml-2" v-if="screenData.errors.receive_weight">
                                      ( {{ screenData.errors.receive_weight[0] }} )
                                  </label>
                                <div class="input-group">

                                    <input id="new_receive_weight" type="text" tabindex="1" placeholder="New Weight" class="form-control" @keypress="$h.preventInvalidInput" v-model="form_data.receive_weight" autocomplete="off"/>

                                </div>
                              </div>
                              <div>
                                <div class="w-full sm:w-auto flex items-center mt-7 justify-end">
                                    <button class="btn btn-primary shadow-md mr-2" @click="issueLabSubmit" :disabled="screenData.return_progress">
                                        Mix Receive Packets
                                        <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!screenData.return_progress" />
                                    </button>
                                </div>
                              </div>

                          </div>

                      </div>
                  </div>

                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5">

                          <div class="col-span-4 xl:col-span-4">

                              <div>
                                  <label for="update-profile-form-6" class="form-label font-bold">
                                      Total Scanned Pcs. :&nbsp;</label>
                                  <label for="update-profile-form-6" class="form-label">{{screenData.total_scan_pack}} </label>
                              </div>

                          </div>
                          <div class="col-span-4 xl:col-span-4">
                              <div>
                                  <label for="update-profile-form-6" class="form-label font-bold">
                                      Total Pcs Weight. :&nbsp;</label>
                                  <label for="update-profile-form-6" class="form-label">{{$h.myFloat(screenData.total_scan_pack_wt)}} </label>
                              </div>
                          </div>
                          <div class="col-span-4 xl:col-span-4 text-right">
                              <div>
                                  <button class="btn btn-sm btn-primary shadow-md mr-2 mb-2" @click="removeGridRowSelect" :disabled="screenData.delet_row">
                                      Remove Selected Row
                                  </button>
                              </div>
                          </div>
                          <div class="col-span-12 ">
                              <ag-grid-vue class="ag-theme-alpine" style="height: 500px" :columnDefs="columnDefs.value" :rowData="rowData.value" :defaultColDef="defaultColDef" rowSelection="multiple" :rowMultiSelectWithClick="true" animateRows="true" @grid-ready="onGridReady" @selection-changed="onGridRowSelect" :overlayNoRowsTemplate="overlayNoRowsTemplate">
                              </ag-grid-vue>
                          </div>

                      </div>
                  </div>
              </div>
              <!-- END: Input -->
          </div>
      </div>
      <!-- END: HTML Table Data -->
      <div id="receive-items-modal" ref="receive-items-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="font-medium text-base mr-auto">Receive Details</h2>
            </div>
            <div class="modal-body">


              <h3 class="font-medium text-base mr-auto">Mixed Packet Details</h3>
              <table class="w-full border">
                <thead>
                  <tr>
                    <th class="text-center">Packet No</th>
                    <th class="text-center">Stock No</th>
                    <th class="text-center">Pieces</th>
                    <th class="text-center">Weight</th>
                    <th class="text-center">Shape</th>
                    <th class="text-center">Color</th>
                    <th class="text-center">Purity</th>
                    <th class="text-center">Cut</th>
                    <th class="text-center">Polish</th>
                    <th class="text-center">Symmetry</th>
                    <th class="p-2 whitespace-nowrap text-center">Print</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border">
                    <td class="text-center">{{ (screenData.mix_data.number ? screenData.mix_data.number : '-') }}</td>
                    <td class="text-center">{{ (screenData.mix_data.vepari_no ? screenData.mix_data.vepari_no : '-') }}</td>
                    <td class="text-center">{{ (screenData.mix_data.pieces ? screenData.mix_data.pieces : '-') }}</td>
                    <td class="text-center">{{ (screenData.mix_data.weight ? screenData.mix_data.weight : '-') }}</td>
                    <td class="text-center">{{ (screenData.mix_data.shape_item ? screenData.mix_data.shape_item.shape_name : '-') }}</td>
                    <td class="text-center">{{ (screenData.mix_data.color_item ? screenData.mix_data.color_item.color_name : '-') }}</td>
                    <td class="text-center">{{ (screenData.mix_data.purity_item ? screenData.mix_data.purity_item.purity_name : '-') }}</td>
                    <td class="text-center">{{ (screenData.mix_data.cut_item ? screenData.mix_data.cut_item.cut_name : '-') }}</td>
                    <td class="text-center">{{ (screenData.mix_data.polish_item ? screenData.mix_data.polish_item.polish_name : '-') }}</td>
                    <td class="text-center">{{ (screenData.mix_data.symmetry_item ? screenData.mix_data.symmetry_item.symmetry_name : '-') }}</td>
                    <td class="py-2 font-medium text-center">
                        <div class="flex justify-start items-center p-0 m-0">
                          <div>
                            <button class="text-theme-1 mr-3" @click="print_single_stone(screenData.mix_data)">
                                <PrinterIcon class="w-5 h-5" />
                            </button>
                          </div>
                      </div>
                    </td>
                  </tr>
                </tbody>

              </table>

              <h3 class="font-medium text-base mr-auto mt-5">Receive Details</h3>
              <table class="w-full border">
                <thead>
                  <tr>
                    <th class="text-center">No</th>
                    <th class="text-center">Packet No</th>
                    <th class="text-center">Stock No</th>
                    <th class="text-center">Process</th>
                    <th class="text-center">Issue Weight</th>
                    <th class="text-center">Status</th>
                    <th class="text-center">Message</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border" v-for="(plan,index) in screenData.receive_data" :key="index">
                    <td class="text-center">
                      <p>{{ index + 1 }}</p>
                    </td>
                    <td class="text-center">{{ (plan.packet_no ? plan.packet_no : '-') }}</td>
                    <td class="text-center">{{ (plan.vepari_no ? plan.vepari_no : '-') }}</td>
                    <td class="text-center">{{ (plan.process ? plan.process : '-') }}</td>
                    <td class="text-center">{{ (plan.issue_weight ? plan.issue_weight : '-') }}</td>
                    <td class="text-center" :class="{'text-red' : plan.status_color == 'secondary','text-green' : plan.status_color == 'success'}">{{ (plan.status ? plan.status : '-') }}</td>
                    <td class="text-center" :class="{'text-red' : plan.status_color == 'secondary','text-green' : plan.status_color == 'success'}">{{ (plan.message ? plan.message : '-') }}</td>
                  </tr>
                </tbody>

              </table>

            </div>
          </div>
        </div>
      </div>
      <BarcodeSingleStone ref="BarcodeSingleStone" />
  </div>
  </template>

  <script>
  import {
      defineComponent,
      ref,
      reactive,
      onMounted,
      inject
  } from 'vue'
  import feather from 'feather-icons'
  import VueRouter from 'vue-router'
  import {
      AgGridVue
  } from "ag-grid-vue3";
  import {
      useRouter,
      useRoute
  } from 'vue-router'
  import {
      BASE_URL
  } from '@/constants/config.js'
  import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
  import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
  import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
  import {
      stringify
  } from 'json5';
  import BarcodeSingleStone from '@/components/barcodes/Main.vue'
  import { helper as $h } from '@/utils/helper';

  export default defineComponent({
      components: {
          AgGridVue,
          ShapeRenderer, BarcodeSingleStone
      },
      setup() {
        const BarcodeSingleStone = ref()
          const gridApi = ref(null);
          const router = useRouter()
          const route = useRoute()
          const swal = inject('$swal')
          const filter = reactive({
              field: 'lot_no',
              type: 'like',
              value: ''
          })

          const getRowId = (params) => {
              params.data.sr_no
              console.log(params.data.sr_no);
          }
          const screenData = reactive({
              scanload: false,
              color_arr: [],
              receive_data: [],
              mix_data: [],
              purity_arr: [],
              shape_arr: [],
              polish_arr: [],
              symmetry_arr: [],
              fluerocent_arr: [],
              tension_arr: [],
              charni_arr: [],
              cut_arr: [],
              shape_items: [],
              color_items: [],
              purity_items: [],
              cut_items: [],
              polish_items: [],
              symmetry_items: [],
              errors: [],

              return_progress: false,
              scan_lot_no: '',
              lab_type: '',
              total_scan_pack_wt: 0,
              selected_party: '',
              total_scan_pack: 0,
              process_list: [],
              kapan_list: []

          })

          const form_data = reactive({
            process_id: "0",
            shape_id: "0",
            color_id: "0",
            kapan_id: "0",
            purity_id: "0",
            size_id: "0",
            receive_weight: "",
            cut_id: "0",
            polish_id: "0",
            symmetry_id: "0",
          })

          const onGridReady = (params) => {
              gridApi.value = params.api;
          };

          const rowData = reactive({
              value: []
          });

          const overlayNoRowsTemplate = 'Scan packets for Lab Issue!'

          const columnDefs = reactive({
              value: [{
                      headerName: "#",
                      field: 'sr_no',
                      maxWidth: 60,
                  },
                  {
                      headerName: "Packet No",
                      field: 'lot_no',
                      minWidth: 140
                  },
                  {
                      headerName: "Stock No",
                      field: 'stock_no',
                      minWidth: 140
                  },
                  {
                      headerName: "Issue Wt (Ct)",
                      field: 'issue_weight',
                      editable: false,
                      minWidth: 140,
                  },
                  {
                      headerName: "Polish Wt (Ct)",
                      field: 'cr_polish_weight',
                      editable: true,
                      minWidth: 140,
                      valueSetter: (params) => {
                          if (params.newValue == '') {
                              // params.data.color_name = ''
                              // swal('Warning!', "Weight Can't be Empty!", "warning")
                              return false;
                            } else {
                              if($h.myFloat(params.newValue) || params.newValue == 0) {

                              } else {
                                swal('Warning!',"Invalid Polish Weight" +" Row!","warning")
                                return false;

                              }
                              if(parseFloat(params.newValue) > parseFloat(params.data.receive_weight)) {
                                swal('Warning!',"Polish Weight Must be less then Receive Weight" +" Row!","warning")
                                return false;

                              }
                            }
                            params.data.cr_polish_weight = params.newValue;

                              // swal('Warning!', "Invalid Color!", "warning")
                              // setTimeout(focusScan, 50);
                              // return valueChanged;

                          }
                      },
                  {
                      headerName: "Process",
                      field: 'process_name',
                      minWidth: 140
                  },
                  {
                      headerName: "Employee Code",
                      field: 'employee_code',
                      minWidth: 140
                  },
                  {
                      headerName: "Employee",
                      field: 'employee_name',
                      minWidth: 140
                  },
                  {
                      headerName: "Shape",
                      field: 'shape_name',
                      minWidth: 140
                  },
                  {
                      headerName: "Color",
                      field: 'color_name',
                      minWidth: 140
                  },
                  {
                      headerName: "Purity",
                      field: 'purity_name',
                      minWidth: 140
                  },
                  {
                      headerName: "Cut",
                      field: 'cut_name',
                      minWidth: 140
                  },
                  {
                      headerName: "Polish",
                      field: 'polish_name',
                      minWidth: 140
                  },
                  {
                      headerName: "Symmetry",
                      field: 'symmetry_name',
                      minWidth: 140
                  },
                  {
                      headerName: "Fluorescent",
                      field: 'fluerocent_name',
                      minWidth: 140
                  },
                  {
                      headerName: "Tension",
                      field: 'tension_name',
                      minWidth: 140
                  }
              ]
          });

          const defaultColDef = {
              sortable: true,
              filter: true,
              flex: 1
          };

          // Get Process By Type
          const get_process_list = () => {
              let params = {
                process_type: "makeable",
                mix_stock: 1
              }
              let promise = axios({
                  url: "/api/master/dp_process",
                  method: "post",
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),

                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                    screenData.process_list = data.items

                  })
                  .catch(_error => {
                    screenData.process_list = []

                  });
          }

          // Get Process By Type
          const get_size_list = () => {
              let params = {
                process_type: "makeable"
              }
              let promise = axios({
                  url: "/api/master/dp_mix_charni",
                  method: "post",
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),

                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                    screenData.charni_arr = data.items

                  })
                  .catch(_error => {
                    screenData.charni_arr = []

                  });
          }

          // Get Kapan By Process
          const get_kapan_list = () => {
              let params = {
                process_id: form_data.process_id
              }
              let promise = axios({
                  url: "/api/purchase/mpc_kapan",
                  method: "post",
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),

                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                    screenData.kapan_list = data.items

                  })
                  .catch(_error => {
                    screenData.kapan_list = []

                  });
          }

          // Get Parameter Details
          const get_all_details = () => {
              let params = {

              }
              let promise = axios({
                  url: "/api/master/get_all_parameters",
                  method: "post",
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),

                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                    screenData.color_items = data.color
                    screenData.purity_items = data.purity
                    screenData.shape_items = data.shape
                    screenData.cut_items = data.cut
                    screenData.polish_items = data.polish
                    screenData.symmetry_items = data.symmetry

                      // screenData.shape_arr = data.items

                  })
                  .catch(_error => {
                      screenData.color_arr = []
                      screenData.purity_arr = []
                      screenData.shape_arr = []
                      screenData.polish_arr = []
                      screenData.symmetry_arr = []
                      screenData.fluerocent_arr = []
                      screenData.tension_arr = []
                      screenData.cut_arr = []

                  });
          }

          //Edit user
          const scanAndAddPack = () => {
              if(form_data.process_id == "0") {
                swal("Warning !", "Process Must Be Selected.", "warning")
              } else if(form_data.kapan_id == "0") {
                swal("Warning !", "Process Must Be Selected.", "warning")
              } else {
                let codelength = screenData.scan_lot_no.trim().length;
                if (codelength > 4) {
                    screenData.scanload = true;
                    //Check already in array
                    var i = rowData.value.length;
                    while (i--) {
                        if (rowData.value[i]['barcode'] == screenData.scan_lot_no || rowData.value[i]['lot_no'] == screenData.scan_lot_no) {
                            screenData.scan_lot_no = "";
                            swal("Warning!", "Already in list, Scan other packet!", "warning");
                            screenData.scanload = false;
                            return;
                        }
                    }

                    //get lot details
                    const data = {
                        barcode: screenData.scan_lot_no,
                        kapan_id: form_data.kapan_id,
                        process_id: form_data.process_id
                    };
                    let url_post = '';
                    if (screenData.scan_lot_no != '') {
                        url_post = "/api/full-process/get_lab_scanned_packet_details_party"
                    } else {
                        url_post = "/api/full-process/get_lab_scanned_packet_details_party"
                    }
                    let promise = axios({
                        url: url_post,
                        method: "post",
                        data: data,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),

                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        },
                    });
                    return promise
                        .then((result) => result.data)
                        .then((data) => {
                            console.log(data);
                            if (data.packet_details == 'No') {
                                swal("Warning!", data.error_msg, "warning");
                                screenData.scanload = false;
                                document.querySelector('[id="scan_lot_no"]').focus();
                            } else if (data.packet_details == 'process') {
                                swal("Warning!", "Scanned barcode is already in process", "warning");
                                screenData.scanload = false;
                                document.querySelector('[id="scan_lot_no"]').focus();
                            } else {
                                let pack_details = data.packet_details;

                                // if(screenData.lab_type == '') {

                                //   screenData.lab_type = pack_details.lab
                                // }
                                // console.log('Hii data');
                                // let party_length = screenData.party_ar.length

                                // for(let i = 0; i< party_length; i++) {
                                //   if(screenData.party_ar[i].first_name == screenData.lab_type) {

                                //     screenData.selected_party = stringify(screenData.party_ar[i].id)
                                //     console.log(screenData.selected_party)
                                //     break;
                                //   }
                                // }
                                screenData.total_scan_pack = screenData.total_scan_pack + 1;
                                console.log('Push data');
                                rowData.value.push({
                                    "sr_no": screenData.total_scan_pack,
                                    "lot_no": pack_details.number,
                                    "stock_no": pack_details.vepari_no,
                                    "cr_weight": pack_details.cr_weight,
                                    "cr_polish_weight": pack_details.cr_polish_weight,
                                    "shape_name": pack_details.shape_name,
                                    "color_name": pack_details.color_name,
                                    "purity_name": pack_details.purity_name,
                                    "cut_name": pack_details.cut_name,
                                    "polish_name": pack_details.polish_name,
                                    "purity_name": pack_details.purity_name,
                                    "symmetry_name": pack_details.symmetry_name,
                                    "fluerocent_name": pack_details.fluerocent_name,
                                    "tension_name": pack_details.tension_name,
                                    "barcode": pack_details.barnum,
                                    "packet_id": pack_details.id,
                                    "process_name": pack_details.process_name,
                                    "issue_date": pack_details.issue_date,
                                    "employee_name": pack_details.employee_name,
                                    "employee_code": pack_details.employee_code,
                                    "process_code": pack_details.process_code,
                                    "process_short_code": pack_details.process_short_code,
                                    "issue_weight": pack_details.issue_weight,
                                    "receive_weight": (pack_details.receive_weight ? pack_details.receive_weight : ''),
                                    "assort_weight": (pack_details.assort_weight ? pack_details.assort_weight : ''),
                                    "net_rejection_weight": (pack_details.net_rejection_weight ? pack_details.net_rejection_weight : ''),
                                    "comment": '',
                                    "is_for_p": false,
                                    "is_semi_polish": false,
                                    "process_id": pack_details.process_id,
                                });

                                screenData.total_scan_pack_wt = parseFloat(screenData.total_scan_pack_wt) + parseFloat(pack_details.issue_weight);

                                gridApi.value.setRowData(rowData.value);
                                screenData.scan_lot_no = "";
                                screenData.scanload = false;
                                focusScan();
                                // if (rowData.value.length == 1) {
                                //     setTimeout(focusLstRow, 50);
                                // } else {
                                //     focusLstRow();
                                // }
                                // console.log(rowData.value);
                            }
                        })
                        .catch((_error) => {
                            screenData.scanload = false;
                            return [];
                        });
                }
              }
          }

          const onGridRowSelect = () => {
              console.log(gridApi.value.getSelectedRows().length);
              if (gridApi.value.getSelectedRows().length == 0) {
                  screenData.delet_row = true;
              } else {
                  screenData.delet_row = false;
              }
          }

          const issueLabSubmit = () => {



              const data = {
                  packet_details: rowData.value,
                  process_id: (form_data.process_id == '0' ? "" : form_data.process_id),
                  shape_id: (form_data.shape_id == '0' ? "" : form_data.shape_id),
                  color_id: (form_data.color_id == '0' ? "" : form_data.color_id),
                  kapan_id: (form_data.kapan_id == '0' ? "" : form_data.kapan_id),
                  purity_id: (form_data.purity_id == '0' ? "" : form_data.purity_id),
                  size_id: (form_data.size_id == '0' ? "" : form_data.size_id),
                  cut_id: (form_data.cut_id == '0' ? "" : form_data.cut_id),
                  polish_id: (form_data.polish_id == '0' ? "" : form_data.polish_id),
                  symmetry_id: (form_data.symmetry_id == '0' ? "" : form_data.symmetry_id),
                  receive_weight: form_data.receive_weight,
                  ip: localStorage.getItem('sk_key')
              };

              console.log(data);

              //screenData.return_progress = true;
              swal({
                  title: "Are you sure?",
                  text: "You are about to Mix Receive packets!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Receive",
                  showLoaderOnConfirm: true,
                  cancelButtonText: "Cancel",

                  preConfirm: login => {

                      let promise = axios({
                          url: "api/full-process/receive_mixed_packets",
                          method: "post",
                          data: data,
                          baseURL: BASE_URL,
                          headers: {
                              Authorization: "Bearer " + localStorage.getItem("token"),

                          },
                          config: {
                              headers: {
                                  Accept: "application/json"
                              }
                          }
                      });
                      return promise
                          .then(result => result.data)
                          .then(data => {
                              if (data.status == "success") {

                                  screenData.receive_data = data.data
                                  screenData.mix_data = data.mix_packet
                                  cash('#receive-items-modal').modal('show')
                                  rowData.value = []
                                  screenData.lab_type = ''
                                  screenData.total_scan_pack_wt = 0
                                  screenData.total_scan_pack = 0

                                } else if (data.status == "error") {
                                  screenData.receive_data = data.data
                                  screenData.mix_data = data.mix_packet
                                  cash('#receive-items-modal').modal('show')
                                  screenData.lab_type = ''
                                  screenData.total_scan_pack_wt = 0
                                  screenData.total_scan_pack = 0
                                  screenData.return_progress = false;
                                  return;
                              }
                          })
                          .catch(_error => {
                            screenData.errors = _error.response.data.errors

                            setTimeout(() => {
                              screenData.errors = []
                            }, 5000);


                              screenData.return_progress = false;
                          });
                  },
                  allowOutsideClick: () => !$Swal.isLoading()
              }).then(result => {
                  screenData.errors = _error.response.data.errors

                            setTimeout(() => {
                              screenData.errors = []
                            }, 5000);


                              screenData.return_progress = false;
              });
          }

          const removeGridRowSelect = () => {
              console.log(gridApi.value.getSelectedRows());
              const sel = gridApi.value.getSelectedRows();
              console.log(sel);

              let remove_rows = [];
              var i = sel.length;
              while (i--) {
                  remove_rows.push(sel[i]['barcode']);
              }
              gridApi.value.applyTransaction({
                  remove: sel
              });
              console.log('Removeing row');
              console.log(remove_rows);
              var j = rowData.value.length;
              while (j--) {
                  if (remove_rows.includes(rowData.value[j]['barcode'])) {
                      rowData.value.splice(j, 1);
                  }
              }

              setTimeout(calculateTotal(), 50);
          }

          function calculateTotal () {
            screenData.total_scan_pack = rowData.value.length
            screenData.total_scan_pack_wt = 0;

            rowData.value.forEach(element => {
              screenData.total_scan_pack_wt = parseFloat(screenData.total_scan_pack_wt) + parseFloat(element.issue_weight);
            });
          }

          const focusScan = () => {

              document.querySelector('[id="scan_lot_no"]').focus();
          }

          onMounted(() => {

              document.querySelector('[id="scan_lot_no"]').focus();
              rowData.value = []
              get_all_details()
              get_process_list()
              get_size_list()
          })

          const print_single_stone = (item) => {
            BarcodeSingleStone.value.setPrintData({
              id: item.id,
              vepari_no: item.vepari_no,
              number: item.number,
              type: item.type,
              stock_type: item.stock_type,
              pieces: item.pieces,
              mk_weight: item.mk_weight,
              weight: item.weight,
              polish_weight: item.polish_weight,
              barnum: item.barnum,
              shape: (item.shape_item?.shape_name ?? ""),
              color: (item.color_item?.color_name ?? ""),
              purity: (item.purity_item?.purity_name ?? ""),
              cut: (item.cut_item?.cut_name ?? ""),
              polish: (item.polish_item?.polish_name ?? ""),
              symmetry: (item.symmetry_item?.symmetry_name ?? ""),
              fluerocent: (item.fluerocent_item?.fluerocent_name ?? ""),
              tension: (item.tension_item?.tension_name ?? ""),
              stone: (item.stone_item?.stone_name ?? ""),
              length: item.length,
              width: item.width,
              height: item.height,
              date: item.created_at,
            })
          }

          return {
              filter,form_data,
              screenData,
              columnDefs,
              rowData,
              defaultColDef,
              overlayNoRowsTemplate,
              scanAndAddPack,
              onGridReady,
              issueLabSubmit,
              getRowId,
              removeGridRowSelect,
              onGridRowSelect,
              get_kapan_list,
              BarcodeSingleStone,
              print_single_stone,
              deselectRows: () => {
                  gridApi.value.deselectAll()
              }
          }

      }
  })
  </script>
