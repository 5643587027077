<template>
  <button class="hidden" v-print="'#single-stone'" ref="single_stone_print"></button>
  <div class="hidden">
    <div id="single-stone" class="absolute top-0 left-0 p-0 m-0" v-if="print_data.id">
      <div v-if="page_data.base_url == 'https://santkrupa.kodllin.com/apis/public/'">
        <div class="flex items-center justify-between mt-1 px-2">
          <div class="flex items-center justify-start">
          <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;">{{ $h.myFloat(print_data.weight) }} &nbsp;</span>
          <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;" v-if="print_data.mk_weight">({{ print_data.stock_type == 2 ? (print_data.pieces  + ' | ') : '' }} {{ $h.myFloat(print_data.mk_weight) }}) &nbsp;</span>
          <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;" v-if="print_data.polish_weight">({{ $h.myFloat(print_data.polish_weight) }})</span>
        </div>
          <div class="flex items-center justify-end">
            <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;" v-if="print_data.purity">{{ print_data.purity }}</span>
            <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;" v-if="print_data.purity && print_data.shape">&nbsp;/&nbsp;</span>
            <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;" v-if="print_data.color">{{ print_data.color }}</span>
            <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;" v-if="print_data.color && print_data.shape">&nbsp;/&nbsp;</span>
            <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;" v-if="print_data.shape">{{ print_data.shape }}</span>
          </div>
        </div>
        <div class="flex items-center justify-center w-full">
          <div><VueBarcode class="py-0 my-0" :value="print_data.barnum" :options="{ displayValue: false,height:22,width:1.8 }" tag="svg"></VueBarcode></div>
        </div>
        <div class="flex items-center justify-between px-1 w-full">
          <div><span class="m-auto" style="font-size: 12px ;font-weight: 900;line-height: 15px;" v-if="print_data.vepari_no">{{ print_data.vepari_no }}</span></div>
          <div><p class="text-right p-0 mt-1 text-black" style="font-size: 7px;line-height: 1;letter-spacing: 2px;font-weight: bold;">KODLLIN <br> TECHONOLOGY</p></div>
        </div>


      </div>
      <div v-else>
        <table class="p-0 m-0 w-full text-black" border="collapse">
            <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_data.number }}</th>
                <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ print_data.vepari_no }}</th>
                <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ print_data.mk_weight ? print_data.mk_weight : print_data.weight }}</th>
                <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;" v-if="print_data.mk_weight != null">PW : {{ print_data.polish_weight }}</th>
            </tr>
            <tr class="text-center m-0 p-0">
                <th colspan="9" class="text-center">
                    <VueBarcode :value="print_data.barnum" :options="{ displayValue: false,height:22,width:2 }" tag="svg"></VueBarcode>
                </th>
            </tr>
        </table>
        <p class="p-0 text-black w-full text-right"
        style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span
          class="text-right">{{ print_data.stone_name }}</span> / <span class="text-right">Kodllin
          TECHONOLOGY</span></p>
        <!-- <table class="p-0 m-0 w-full text-black" border="collapse" v-if="print_data.type == 'rough'">
            <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_data.number }}</th>
                <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ print_data.vepari_no }}</th>
                <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ print_data.mk_weight ? print_data.mk_weight : print_data.weight }}</th>
                <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">PW : {{ print_data.polish_weight }}</th>
            </tr>
            <tr class="text-center m-0 p-0">
                <th colspan="9" class="text-center">
                    <VueBarcode :value="print_data.barnum" :options="{ displayValue: true,height:27,width:2 }" tag="svg"></VueBarcode>
                </th>
            </tr>
            <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="print_data.mk_weight != null">
                <th class="border m-auto border-black">SHP</th>
                <th class="border m-auto border-black">CL</th>
                <th class="border m-auto border-black">PRT</th>
                <th class="border m-auto border-black">CT</th>
                <th class="border m-auto border-black">PL</th>
                <th class="border m-auto border-black">SYM</th>
                <th class="border m-auto border-black">Fl</th>
                <th class="border m-auto border-black">TN</th>
                <th class="border m-auto border-black">STN</th>
            </tr>
            <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="print_data.mk_weight != null">
                <th class="border m-auto border-black">{{ print_data.shape }}</th>
                <th class="border m-auto border-black">{{ print_data.color }}</th>
                <th class="border m-auto border-black">{{ print_data.purity }}</th>
                <th class="border m-auto border-black">{{ print_data.cut }}</th>
                <th class="border m-auto border-black">{{ print_data.polish }}</th>
                <th class="border m-auto border-black">{{ print_data.symmetry }}</th>
                <th class="border m-auto border-black">{{ print_data.fluerocent }}</th>
                <th class="border m-auto border-black">{{ print_data.tension }}</th>
                <th class="border m-auto border-black">{{ print_data.stone }}</th>
            </tr>
            <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="print_data.mk_weight == null">
                <th class="border m-auto border-black" colspan="3">L</th>
                <th class="border m-auto border-black" colspan="3">W</th>
                <th class="border m-auto border-black" colspan="3">H</th>
            </tr>
            <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="print_data.mk_weight == null">
                <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(print_data.length) }}</th>
                <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(print_data.width) }}</th>
                <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(print_data.height) }}</th>
            </tr>
        </table>
        <table class="p-0 m-0 w-full text-black" border="collapse" v-if="print_data.type == 'makeable' && print_data.stock_type == 1">
            <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_data.number }}</th>
                <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ print_data.vepari_no }}</th>
                <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ print_data.mk_weight ? print_data.mk_weight : print_data.weight }}</th>
                <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">PW : {{ print_data.polish_weight }}</th>
            </tr>
            <tr class="text-center m-0 p-0">
                <th colspan="9" class="text-center">
                    <VueBarcode :value="print_data.barnum" :options="{ displayValue: true,height:27,width:2 }" tag="svg"></VueBarcode>
                </th>
            </tr>
            <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="print_data.mk_weight != null">
                <th class="border m-auto border-black">SHP</th>
                <th class="border m-auto border-black">CL</th>
                <th class="border m-auto border-black">PRT</th>
                <th class="border m-auto border-black">CT</th>
                <th class="border m-auto border-black">PL</th>
                <th class="border m-auto border-black">SYM</th>
                <th class="border m-auto border-black">Fl</th>
                <th class="border m-auto border-black">TN</th>
                <th class="border m-auto border-black">STN</th>
            </tr>
            <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="print_data.mk_weight != null">
                <th class="border m-auto border-black">{{ print_data.shape }}</th>
                <th class="border m-auto border-black">{{ print_data.color }}</th>
                <th class="border m-auto border-black">{{ print_data.purity }}</th>
                <th class="border m-auto border-black">{{ print_data.cut }}</th>
                <th class="border m-auto border-black">{{ print_data.polish }}</th>
                <th class="border m-auto border-black">{{ print_data.symmetry }}</th>
                <th class="border m-auto border-black">{{ print_data.fluerocent }}</th>
                <th class="border m-auto border-black">{{ print_data.tension }}</th>
                <th class="border m-auto border-black">{{ print_data.stone }}</th>
            </tr>
            <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="print_data.mk_weight == null">
                <th class="border m-auto border-black" colspan="3">L</th>
                <th class="border m-auto border-black" colspan="3">W</th>
                <th class="border m-auto border-black" colspan="3">H</th>
            </tr>
            <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="print_data.mk_weight == null">
                <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(print_data.length) }}</th>
                <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(print_data.width) }}</th>
                <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(print_data.height) }}</th>
            </tr>
        </table>
        <table class="p-0 m-0 w-full text-black" border="collapse" v-if="print_data.type == 'makeable' && print_data.stock_type == 2">
            <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                <th class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ print_datanumber }}</th>
                <th class="border m-auto border-black" style="font-size: 9px;line-height: 8px;" colspan="2">Lot : {{ print_datavepari_no }}</th>
            </tr>
            <tr class="text-center m-0 p-0">
              <th colspan="3" class="text-center">
                <VueBarcode :value="print_databarnum" :options="{ displayValue: false,height:22,width:2 }" tag="svg"></VueBarcode>
              </th>
            </tr>
            <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
              <th class="border m-auto border-black" style="font-size: 9px;line-height: 8px;" v-if="print_datamk_weight != null">PCS : {{ print_datapieces }}</th>
                <th class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ print_datamk_weight ? print_datamk_weight : print_dataweight }}</th>
                <th class="border m-auto border-black" style="font-size: 9px;line-height: 8px;" v-if="print_datamk_weight != null">PW : {{ print_datapolish_weight }}</th>
            </tr>
          </table>
        <p class="text-right p-0 mt-1 text-black" style="font-size: 7px;line-height: 1;letter-spacing: 4px;font-weight: bold;">BLUEMOON TECHONOLOGY</p> -->

      </div>
    </div>
  </div>
  <button class="hidden" v-print="'#multi-stone'" ref="multi_stone_print"></button>
  <div class="hidden">
    <div id="multi-stone" class="absolute top-0 left-0 p-0 m-0">
      <div style="page-break-before: always;" v-for="(item,index) in print_arr_data" :key="index">
        <div v-if="page_data.base_url == 'https://santkrupa.kodllin.com/apis/public/' && item.id">
          <div class="flex items-center justify-between mt-1 px-2">
            <div class="flex items-center justify-start">
            <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;">{{ $h.myFloat(item.weight) }} &nbsp;</span>
            <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;" v-if="item.mk_weight">({{ item.stock_type == 2 ? (item.pieces  + ' | ') : '' }} {{ $h.myFloat(item.mk_weight) }}) &nbsp;</span>
            <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;" v-if="item.polish_weight">({{ $h.myFloat(item.polish_weight) }})</span>
          </div>
            <div class="flex items-center justify-end">
              <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;" v-if="item.purity">{{ item.purity }}</span>
              <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;" v-if="item.purity && item.shape">&nbsp;/&nbsp;</span>
              <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;" v-if="item.color">{{ item.color }}</span>
              <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;" v-if="item.color && item.shape">&nbsp;/&nbsp;</span>
              <span class="m-auto" style="font-size: 10px; font-weight: 900;line-height: 10px;" v-if="item.shape">{{ item.shape }}</span>
            </div>
          </div>
          <div class="flex items-center justify-center w-full">
            <div><VueBarcode class="py-0 my-0" :value="item.barnum" :options="{ displayValue: false,height:22,width:1.8 }" tag="svg"></VueBarcode></div>
          </div>
          <div class="flex items-center justify-between px-1 w-full">
            <div><span class="m-auto" style="font-size: 12px ;font-weight: 900;line-height: 15px;" v-if="item.vepari_no">{{ item.vepari_no }}</span></div>
            <div><p class="text-right p-0 mt-1 text-black" style="font-size: 7px;line-height: 1;letter-spacing: 2px;font-weight: bold;">KODLLIN <br> TECHONOLOGY</p></div>
          </div>

        </div>
        <div v-else>
          <table class="p-0 m-0 w-full text-black" border="collapse">
            <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ item.number }}</th>
                <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ item.vepari_no }}</th>
                <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ item.mk_weight ? item.mk_weight : item.weight }}</th>
                <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;" v-if="item.mk_weight != null">PW : {{ item.polish_weight }}</th>
            </tr>
            <tr class="text-center m-0 p-0">
                <th colspan="9" class="text-center">
                    <VueBarcode :value="item.barnum" :options="{ displayValue: false,height:22,width:2 }" tag="svg"></VueBarcode>
                </th>
            </tr>
        </table>
        <p class="p-0 text-black w-full text-right"
        style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span
          class="text-right">{{ item.stone_name }}</span> / <span class="text-right">Kodllin
          TECHONOLOGY</span></p>
          <!-- <table class="p-0 m-0 w-full text-black" border="collapse" v-if="item.type == 'rough'">
              <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                  <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ item.number }}</th>
                  <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ item.vepari_no }}</th>
                  <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ item.mk_weight ? item.mk_weight : item.weight }}</th>
                  <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">PW : {{ item.polish_weight }}</th>
              </tr>
              <tr class="text-center m-0 p-0">
                  <th colspan="9" class="text-center">
                      <VueBarcode :value="item.barnum" :options="{ displayValue: true,height:27,width:2 }" tag="svg"></VueBarcode>
                  </th>
              </tr>
              <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="item.mk_weight != null">
                  <th class="border m-auto border-black">SHP</th>
                  <th class="border m-auto border-black">CL</th>
                  <th class="border m-auto border-black">PRT</th>
                  <th class="border m-auto border-black">CT</th>
                  <th class="border m-auto border-black">PL</th>
                  <th class="border m-auto border-black">SYM</th>
                  <th class="border m-auto border-black">Fl</th>
                  <th class="border m-auto border-black">TN</th>
                  <th class="border m-auto border-black">STN</th>
              </tr>
              <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="item.mk_weight != null">
                  <th class="border m-auto border-black">{{ item.shape }}</th>
                  <th class="border m-auto border-black">{{ item.color }}</th>
                  <th class="border m-auto border-black">{{ item.purity }}</th>
                  <th class="border m-auto border-black">{{ item.cut }}</th>
                  <th class="border m-auto border-black">{{ item.polish }}</th>
                  <th class="border m-auto border-black">{{ item.symmetry }}</th>
                  <th class="border m-auto border-black">{{ item.fluerocent }}</th>
                  <th class="border m-auto border-black">{{ item.tension }}</th>
                  <th class="border m-auto border-black">{{ item.stone }}</th>
              </tr>
              <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="item.mk_weight == null">
                  <th class="border m-auto border-black" colspan="3">L</th>
                  <th class="border m-auto border-black" colspan="3">W</th>
                  <th class="border m-auto border-black" colspan="3">H</th>
              </tr>
              <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="item.mk_weight == null">
                  <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(item.length) }}</th>
                  <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(item.width) }}</th>
                  <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(item.height) }}</th>
              </tr>
          </table>
          <table class="p-0 m-0 w-full text-black" border="collapse" v-if="item.type == 'makeable' && item.stock_type == 1">
              <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                  <th colspan="3" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ item.number }}</th>
                  <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">Lot : {{ item.vepari_no }}</th>
                  <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ item.mk_weight ? item.mk_weight : item.weight }}</th>
                  <th colspan="2" class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">PW : {{ item.polish_weight }}</th>
              </tr>
              <tr class="text-center m-0 p-0">
                  <th colspan="9" class="text-center">
                      <VueBarcode :value="item.barnum" :options="{ displayValue: true,height:27,width:2 }" tag="svg"></VueBarcode>
                  </th>
              </tr>
              <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="item.mk_weight != null">
                  <th class="border m-auto border-black">SHP</th>
                  <th class="border m-auto border-black">CL</th>
                  <th class="border m-auto border-black">PRT</th>
                  <th class="border m-auto border-black">CT</th>
                  <th class="border m-auto border-black">PL</th>
                  <th class="border m-auto border-black">SYM</th>
                  <th class="border m-auto border-black">Fl</th>
                  <th class="border m-auto border-black">TN</th>
                  <th class="border m-auto border-black">STN</th>
              </tr>
              <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="item.mk_weight != null">
                  <th class="border m-auto border-black">{{ item.shape }}</th>
                  <th class="border m-auto border-black">{{ item.color }}</th>
                  <th class="border m-auto border-black">{{ item.purity }}</th>
                  <th class="border m-auto border-black">{{ item.cut }}</th>
                  <th class="border m-auto border-black">{{ item.polish }}</th>
                  <th class="border m-auto border-black">{{ item.symmetry }}</th>
                  <th class="border m-auto border-black">{{ item.fluerocent }}</th>
                  <th class="border m-auto border-black">{{ item.tension }}</th>
                  <th class="border m-auto border-black">{{ item.stone }}</th>
              </tr>
              <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="item.mk_weight == null">
                  <th class="border m-auto border-black" colspan="3">L</th>
                  <th class="border m-auto border-black" colspan="3">W</th>
                  <th class="border m-auto border-black" colspan="3">H</th>
              </tr>
              <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="item.mk_weight == null">
                  <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(item.length) }}</th>
                  <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(item.width) }}</th>
                  <th class="border m-auto border-black" colspan="3">{{ $h.myFloat(item.height) }}</th>
              </tr>
          </table>
          <table class="p-0 m-0 w-full text-black" border="collapse" v-if="item.type == 'makeable' && item.stock_type == 2">
            <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                <th class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">{{ item.number }}</th>
                <th class="border m-auto border-black" style="font-size: 9px;line-height: 8px;" colspan="2">Lot : {{ item.vepari_no }}</th>
            </tr>
            <tr class="text-center m-0 p-0">
              <th colspan="3" class="text-center">
                <VueBarcode :value="item.barnum" :options="{ displayValue: false,height:22,width:2 }" tag="svg"></VueBarcode>
              </th>
            </tr>
            <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
              <th class="border m-auto border-black" style="font-size: 9px;line-height: 8px;" v-if="item.mk_weight != null">PCS : {{ item.pieces }}</th>
                <th class="border m-auto border-black" style="font-size: 9px;line-height: 8px;">RW : {{ item.mk_weight ? item.mk_weight : item.weight }}</th>
                <th class="border m-auto border-black" style="font-size: 9px;line-height: 8px;" v-if="item.mk_weight != null">PW : {{ item.polish_weight }}</th>
            </tr>
          </table>
          <p class="text-right p-0 mt-1 text-black" style="font-size: 7px;line-height: 1;letter-spacing: 4px;font-weight: bold;">BLUEMOON TECHONOLOGY</p> -->

        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
 import { BASE_URL } from '@/constants/config.js'
import { reactive, ref } from 'vue';
import moment from 'moment';

const page_data = reactive({
  base_url: BASE_URL,
});

const single_stone_print = ref();
const multi_stone_print = ref();

const print_data = reactive({
    id: "",
    vepari_no: "",
    number: "",
    type: "",
    mk_weight: "",
    weight: "",
    polish_weight: "",
    barnum: "",
    shape: "",
    color: "",
    purity: "",
    cut: "",
    polish: "",
    symmetry: "",
    fluerocent: "",
    tension: "",
    stone: "",
    length: "",
    width: "",
    height: "",
    date: "",
  })

const print_arr_data = ref([]);

const setPrintData = (data) => {
  print_data.id = data.id
  print_data.vepari_no = (data.vepari_no ?? "");
  print_data.number = (data.number ?? "");
  print_data.mk_weight = (data.mk_weight ?? "");
  print_data.weight = (data.weight ?? "");
  print_data.polish_weight = (data.polish_weight ?? "");
  print_data.type = (data.type ?? "");
  print_data.stock_type = (data.stock_type ?? "");
  print_data.barnum = (data.barnum ?? "");
  print_data.shape = (data.shape ?? "");
  print_data.color = (data.color ?? "");
  print_data.purity = (data.purity ?? "");
  print_data.cut = (data.cut ?? "");
  print_data.polish = (data.polish ?? "");
  print_data.symmetry = (data.symmetry ?? "");
  print_data.fluerocent = (data.fluerocent ?? "");
  print_data.tension = (data.tension ?? "");
  print_data.stone = (data.stone ?? "");
  print_data.length = (data.length ?? "");
  print_data.width = (data.width ?? "");
  print_data.height = (data.height ?? "");
  print_data.date = (data.date ? moment(data.date).format("DD-MM-YYYY") : "");

  setTimeout(() => {
    single_stone_print.value.click();
  }, 100);
}
const setPrintAllData = (data) => {

  console.log('dataCallaed')

  print_arr_data.value = data

  print_arr_data.value.forEach(element => {
    element.date = (element.date ? moment(element.date).format("DD-MM-YYYY") : "");
  });
  setTimeout(() => {
    multi_stone_print.value.click();
  }, 100);
}

defineExpose({
  setPrintData,
  setPrintAllData
})
</script>
