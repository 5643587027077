<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Packet Details</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link to="/mix-lot/create" class="btn btn-primary p-2">Merge Stock</router-link>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
          <div class="report-box zoom-in">
            <div class="box p-2">
              <div class="flex items-center justify-between">
                <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                <div class="text-base text-gray-600"> Packets</div>
                <div class="text-xl font-medium leading-8">{{ pagedata.total.total_packets }}</div>
              </div>
            </div>
          </div>
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
        <div class="report-box zoom-in">
          <div class="box p-2">
            <div class="flex items-center justify-between">
              <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
              <div class="text-base text-gray-600"> Weight</div>
              <div class="text-xl font-medium leading-8">{{ pagedata.total.total_weight ? $h.myFloat(pagedata.total.total_weight) + ' ct' : '0.00 ct' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="intro-y box p-3 mt-5">
      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 sm:col-span-3 md:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >KAPAN NO</label
            >
            <TomSelect
              v-model="filter.lot_no"
              :options="{
                placeholder: 'Select Kapan No',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Kapan '
                  }
                }
              }"
              class="w-full"
              @change="getPacketDetails"
            >
            <option value="0">SELECT KAPAN</option>
            <option v-for="lot in pagedata.lots" :key="lot.id" :value="lot.id">{{lot.lot_no}} ({{ lot.vepari_no }})</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3 md:col-span-2" v-if="[1,2,8].includes(user_details.user_role)">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Packet Status</label
            >
            <TomSelect
              v-model="filter.status"
              :options="{
                placeholder: 'Select Status',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Lot '
                  }
                }
              }"
              class="w-full"
              @change="getPacketDetails"
            >
            <option value="0">SELECT STATUS</option>
            <option value="1">AVAILABLE</option>
            <option value="2">IN PROCESS</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3 md:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Packet Number</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.packet_number"
              type="text"
              class="form-control  w-full mt-2 sm:mt-0"
              placeholder="Packet Number"
            />
          </div>
        </div>

        <div class="col-span-6 md:col-span-1">
          <div class="mt-10">
          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-1"
            @click="onResetFilter"
          >
            Reset
          </button>
          </div>
        </div>
        <div class="col-span-4 md:col-span-1">

          <div class="mt-10">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20"
              @click="getPacketDetails"
            >
            Generate
            </button>

          </div>
          </div>
      </div>
      <div class="shadow-md mt-3">
        <table class="w-full mt-2 border">
          <thead>
            <tr class="bg-theme-1 text-white">
              <th class="p-2 whitespace-nowrap text-left">Packet</th>
              <th class="p-2 whitespace-nowrap text-center">Stock No</th>
              <th class="p-2 whitespace-nowrap text-center">Weight</th>
              <th class="p-2 whitespace-nowrap text-center">Cr Weight</th>
              <th class="p-2 whitespace-nowrap text-center">Shape</th>
              <th class="p-2 whitespace-nowrap text-center">Color</th>
              <th class="p-2 whitespace-nowrap text-center">Purity</th>
              <th class="p-2  border-b text-center">
                <MultipleSelect :options="pagedata.parameters.stone" :name="'stone'" @onChecked="onStoneCheck"></MultipleSelect>
              </th>
              <th class="p-2 whitespace-nowrap text-center">Print</th>
              <th class="p-2 whitespace-nowrap text-center">Create Ip</th>

            </tr>
          </thead>
          <tbody v-if="pagedata.packets.length > 0">
            <tr v-for="(packet, index) in pagedata.packets" :key="index" class="border hover:bg-gray-200 p-0 cursor-pointer">
              <td class="py-2 border-b w-40">
                <span v-if="user_details.user_role == 5 || user_details.user_role == 6">
                  <span v-if="packet.transaction_packet_status == 1 && packet.transaction_status == 1">
                    <PackageIcon class="w-6 h-6 font-bold text-theme-1 mr-1" />
                  </span>
                  <span v-else-if="packet.transaction_packet_status == 1 && packet.transaction_status == 2">
                    <PackageIcon class="w-6 h-6 font-bold text-theme-9 mr-1" />
                  </span>
                </span>
                <span v-else>
                  <span v-if="(packet.transaction_packet_status == 1 || (packet.transaction_packet_status == 1 && packet.transaction_status == 1)) && (packet.status == 1 || packet.status == 5)">
                    <span v-if="packet.transaction_packet_status == 1 && packet.transaction_status == 1">
                      <PackageIcon class="w-6 h-6 font-bold text-theme-1 mr-1" />
                    </span>
                    <span v-else-if="packet.transaction_packet_status == 1 && packet.transaction_status == 2">
                      <PackageIcon class="w-6 h-6 font-bold text-theme-9 mr-1" />
                    </span>
                  </span>
                  <span v-else>
                    <PackageIcon class="w-6 h-6 font-bold text-theme-6 mr-1" />
                  </span>
                </span>
                <span :class="{'text-theme-6' : packet.urgent}">{{ packet.number }}</span>
              </td>
              <td class="py-2 border-b text-center">{{ packet.vepari_no }}</td>
              <td class="py-2 border-b text-center">{{ packet.weight }}</td>
              <td class="py-2 border-b text-center">{{ packet.cr_weight }}</td>
              <td class="py-2 border-b text-center">{{ packet.shape_name }}</td>
              <td class="py-2 border-b text-center">{{ packet.color_name }}</td>
              <td class="py-2 border-b text-center">{{ packet.purity_name }}</td>
              <td class="py-2 border-b text-center">{{ packet.stone_name }}</td>
              <td class="py-2 font-medium text-center" v-if="[1,2,8].includes(user_details.user_role)">
                        <div class="flex justify-start items-center p-0 m-0">
                          <div>
                            <button class="text-theme-1 mr-3" @click="print_single_stone(packet)" v-if="user_details.user_role != 3">
                                <PrinterIcon class="w-5 h-5" />
                            </button>
                          </div>
                      </div>
                    </td>
                    <td class="py-2 border-b text-center">{{ packet.issue_trans_ip }}</td>
            </tr>
          </tbody>
        </table>
        <!-- BEGIN: Pagination -->
        <!-- END: Pagination -->
      </div>
      <div v-if="pagedata.packets.length > 0"
          class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3 mb-7"
        >
          <ul class="pagination">
            <li>
              <button class="pagination__link" :disabled="pagedata.current_page == 1" @click="getAvailablePkt(pagedata.first_page_url)">
                <ChevronsLeftIcon class="w-4 h-4" />
              </button>
            </li>
            <li>
              <button class="pagination__link" :disabled="pagedata.prev_page_url == null" @click="getAvailablePkt(pagedata.prev_page_url)">
                <ChevronLeftIcon class="w-4 h-4" />
              </button>
            </li>
            <li v-for="(pagelink,index) in pagelinks" :key="index">
              <button class="pagination__link" :class="{'pagination__link--active' : pagelink.active == true}" v-if="pagelinks[0] != pagelink && pagelinks[pagelinks.length - 1] != pagelink" @click='getAvailablePkt(pagelink.url)'>{{ pagelink.label }}</button>
            </li>
            <li>
              <button class="pagination__link" :disabled="pagedata.next_page_url == null" @click="getAvailablePkt(pagedata.next_page_url)">
                <ChevronRightIcon class="w-4 h-4" />
              </button>
            </li>
            <li>
              <button class="pagination__link" :disabled="pagedata.current_page == pagedata.last_page" @click="getAvailablePkt(pagedata.last_page_url)">
                <ChevronsRightIcon class="w-4 h-4" />
              </button>
            </li>
          </ul>
          <select class="w-20 form-select box mt-3 sm:mt-0" v-model="filter.size" @change="getAvailPkt">
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
          </select>
        </div>
    </div>
    <BarcodeSingleStone ref="BarcodeSingleStone" />
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import MultipleSelect from '@/components/MultipleSelect/Main.vue'
import { computed, defineComponent, onMounted, reactive, toRef, ref, watch } from 'vue';
import store from '@/store';
 import BarcodeSingleStone from '@/components/barcodes/Main.vue'

export default defineComponent({
  components:{
    MultipleSelect, BarcodeSingleStone
  },
  setup() {
    const BarcodeSingleStone = ref()
    const user_details = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      packets: [],
      total: [],
      lots: [],
      filters: true,
      pagelinks: [],
      prev_page_url: null,
      next_page_url: null,
      first_page_url: null,
      last_page_url: null,
      current_page: 1,
      last_page: '',
      parameters: {
        stone: []
      }
    })
    const pagelinks = toRef(pagedata, 'pagelinks')
    const filter = reactive({
      size : 20,
      packet_number : '',
      lot_no : '',
      status : '0',
      type : '0',
      stone: [],
    })
    onMounted(() => {
      getAvailPkt()
      total_packets()
      getLotDetails()
      getDiamontData()
    })

    const print_single_stone = (item) => {
      BarcodeSingleStone.value.setPrintData({
        id: item.id,
        vepari_no: item.vepari_no,
        number: item.number,
        type: item.type,
        stock_type: item.stock_type,
        pieces: item.pieces,
        mk_weight: item.mk_weight,
        weight: item.weight,
        polish_weight: item.polish_weight,
        barnum: item.barnum,
        shape: (item.shape_name ?? ""),
        color: (item.color_name ?? ""),
        purity: (item.purity_name ?? ""),
        cut: (item.cut_name ?? ""),
        polish: (item.polish_name ?? ""),
        symmetry: (item.symmetry_name ?? ""),
        fluerocent: (item.fluerocent_name ?? ""),
        tension: (item.tension_name ?? ""),
        stone: (item.stone_name ?? ""),
        length: item.length,
        width: item.width,
        height: item.height,
        date: item.created_at,
      })
    }
    function onStoneCheck(val) {
      filter.stone = val;
      getPacketDetails()
    }
    // function onCheck(val) {
    //   pagedata.selected = val;
    // }
    function getAvailablePkt(url) {
      axios({
        url: url,
        method: 'post',
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.data
        pagedata.pagelinks = res.data.links
        pagedata.prev_page_url = res.data.prev_page_url
        pagedata.next_page_url = res.data.next_page_url
        pagedata.first_page_url = res.data.first_page_url
        pagedata.last_page_url = res.data.last_page_url
        pagedata.current_page = res.data.current_page
        pagedata.last_page = res.data.last_page
    })
    }
    function getAvailPkt() {
       axios({
        url: `api/purchase/mpc_gap`,
        method: 'post',
        baseURL: BASE_URL,
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.data
        pagedata.pagelinks = res.data.links
        pagedata.prev_page_url = res.data.prev_page_url
        pagedata.next_page_url = res.data.next_page_url
        pagedata.first_page_url = res.data.first_page_url
        pagedata.last_page_url = res.data.last_page_url
        pagedata.current_page = res.data.current_page
        pagedata.last_page = res.data.last_page
      })
    }
    function getDiamontData() {
      axios({
        url: 'api/master/get_all_parameters',
        method: 'post',
        baseURL: BASE_URL,
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.parameters.stone = res.data.stone
      })
    }
    function total_packets() {
      axios({
        url: "api/purchase/mpc_apt",
        method: 'post',
        baseURL: BASE_URL,
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {

          pagedata.total = res.data.total;
        })
    }
    function getLotDetails() {
      axios({
        url: 'api/master/dp_lot_details',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.lots = res.data.items
      })
    }
    // On reset filter
    const onResetFilter = () => {
      filter.lot_no = '0'
      filter.packet_number = ''
      filter.stone = []
      filter.status = '0'
      getPacketDetails()
    }
    function getPacketDetails() {
      getAvailPkt()
      total_packets()
    }
    return {
      getAvailablePkt,
      getAvailPkt,
      pagedata,
      filter,
      user_details,
      pagelinks,
      onStoneCheck,
      onResetFilter,
      getPacketDetails, BarcodeSingleStone, print_single_stone
    }
  }
})
</script>
<style scoped>
input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .custom-number-input input:focus {
    outline: none !important;
  }

  .custom-number-input button:focus {
    outline: none !important;
  }
  .dropdown {
  position: relative;
  cursor: pointer;
}

</style>
