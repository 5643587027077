<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Receive Recut</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">
                <div>
                  <label for="update-profile-form-8" class="form-label"
                    >Scan Lot No</label
                  >
                  <div class="input-group mt-2">
                    <input
                      id="update-profile-form-6"
                      type="text"
                      tabindex="1"
                      placeholder="Scan Barcode"
                      v-model="scan_lot_no"
                      :class="{ 'form-control': true, loading: scanload }"
                      :disabled="scanload"
                      autocomplete="off"
                      @change="scanAndAddPack"
                    />

                    <button
                      type="button"
                      class="btn btn-primary mr-auto"
                      @click="scanAndAddPack"
                      style="border-radius: 0!important"
                    >
                      <SearchIcon class="w-5 h-5 mr-2" /> Search
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary-outline mr-auto"
                      @click="clearSearch('')"
                      style="border-radius: 0!important"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>

    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center justify-between p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Packet Details</h2>
            <div class="text-right">
              <button
                v-print="'#print-all-barcods'"
                class="btn btn-primary btn-sm mr-2"
              >
                Print All
              </button>
              <button
                v-print="'#print-all-barcods-ls'"
                id="print-ls-stones"
                class="btn btn-primary btn-sm mr-2 hidden"
              >
                Print All
              </button>
              <!-- <button class="btn btn-primary btn-sm mr-2" @click="set_part_details" v-if="packet_details.part_ready == 1 && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type)">Add Planning Part</button> -->
              <!-- <button class="btn btn-primary btn-sm mr-2" @click="reset_ls_data" v-if="packet_details.part_ready == 1 && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type)">Reset(LS)</button> -->
              <!-- <button class="btn btn-primary btn-sm mr-2" @click="get_ls_edit_details" v-if="packet_details.part_ready == 1 && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type)">Edit(LS)</button> -->
            </div>
          </div>

          <div class="p-1 pb-10">
            <div class="col-span-12">
              <div style="background-color:#fff;">
                <table class="mt-3 w-full">
                  <thead>
                    <tr class="border-b bg-gray-200 border dark:bg-dark-1">
                      <th class="py-2 text-center border">Pkt No</th>
                      <th class="py-2 text-center border">Weight</th>
                      <th class="py-2 text-center border">Current Weight</th>
                      <th class="py-2 text-center border">MK Weight</th>
                      <th class="py-2 text-center border">Exp. Wt</th>
                      <th class="py-2 text-center border">Sp</th>
                      <th class="py-2 text-center border">Cl</th>
                      <th class="py-2 text-center border">Prt</th>
                      <th class="py-2 text-center border">Ct</th>
                      <th class="py-2 text-center border">Pl</th>
                      <th class="py-2 text-center border">Sym</th>
                      <th class="py-2 text-center border">Stn</th>
                      <th class="py-2 text-center border">Fluc</th>
                      <th class="py-2 text-center border">Tn</th>
                      <th class="py-2 text-center border w-28">Print</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(packet, index) in stock_items"
                      :key="index"
                      class="border hover:bg-gray-200 p-0 cursor-pointer"
                    >
                      <td class="py-1 font-medium text-center border">
                        {{ packet.number }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.weight }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.cr_weight }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.mk_weight }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.polish_weight }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.shape }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.color }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.purity }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.cut }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.polish }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.symmetry }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.stone }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.fluerocent }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.tension }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        <div class="flex justify-between items-center">
                          <input
                            class="show-code form-check-switch mr-0 ml-3 p-0"
                            type="checkbox"
                            v-model="packet.print_status"
                          />
                          <button
                            class="btn btn-primary btn-sm p-1 mr-3"
                            v-print="'#' + packet.number"
                            v-if="packet.print_status == true"
                            @click="update_print_status(packet)"
                          >
                            <PrinterIcon class="w-5 h-5" />
                          </button>
                          <button
                            class="btn btn-primary btn-sm p-1 mr-3"
                            v-print="'#' + packet.number"
                            v-if="packet.print_status == false"
                            disabled
                            @click="update_print_status(packet)"
                          >
                            <PrinterIcon class="w-5 h-5" />
                          </button>
                          <div class="p-0 m-0 hidden">
                            <div
                              :id="packet.number"
                              class="absolute top-0 left-0 p-0 m-0 mt-2"
                            >
                              <table
                                class="p-0 m-0 w-full text-black"
                                border="collapse"
                              >
                                <tr
                                  class="text-center border border-l-0 border-r-0 border-black m-0 p-0"
                                >
                                  <th
                                    colspan="3"
                                    class="border m-auto border-black"
                                    style="font-size: 9px;line-height: 8px;"
                                  >
                                    {{ packet.number }}
                                  </th>
                                  <th
                                    colspan="2"
                                    class="border m-auto border-black"
                                    style="font-size: 9px;line-height: 8px;"
                                  >
                                    Lot : {{ packet.vepari_no }}
                                  </th>
                                  <th
                                    colspan="2"
                                    class="border m-auto border-black"
                                    style="font-size: 9px;line-height: 8px;"
                                  >
                                    RW :
                                    {{
                                      packet.mk_weight
                                        ? packet.mk_weight
                                        : packet.weight
                                    }}
                                  </th>
                                  <th
                                    colspan="2"
                                    class="border m-auto border-black"
                                    style="font-size: 9px;line-height: 8px;"
                                    v-if="packet.mk_weight != null"
                                  >
                                    PW : {{ packet.polish_weight }}
                                  </th>
                                </tr>
                                <tr class="text-center m-0 p-0">
                                  <th colspan="9" class="text-center">
                                    <VueBarcode
                                      :value="packet.barnum"
                                      :options="{
                                        displayValue: false,
                                        height: 22,
                                        width: 2
                                      }"
                                      tag="svg"
                                    ></VueBarcode>
                                  </th>
                                </tr>
                              </table>

                              <p
                                class="p-0 text-black w-full text-right"
                                style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"
                              >
                                <span class="text-right">{{
                                  packet.stone
                                }}</span>
                                /
                                <span class="text-right"
                                  >Kodllin TECHONOLOGY</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="hidden">
                  <div
                    id="print-all-barcods"
                    class="absolute left-0 top-0 p-0 m-0"
                  >
                    <div
                      style="page-break-before: always;"
                      class="mt-2"
                      v-for="(packet, index) in stock_items"
                      :key="index"
                    >
                      <table class="p-0 w-full text-black" border="collapse">
                        <tr
                          class="text-center border border-l-0 border-r-0 border-black m-auto"
                        >
                          <th
                            colspan="3"
                            class="border m-auto border-black"
                            style="font-size: 9px;line-height: 8px;"
                          >
                            {{ packet.number }}
                          </th>
                          <th
                            colspan="2"
                            class="border m-auto border-black"
                            style="font-size: 9px;line-height: 8px;"
                          >
                            Lot : {{ packet.vepari_no }}
                          </th>
                          <th
                            colspan="2"
                            class="border m-auto border-black"
                            style="font-size: 9px;line-height: 8px;"
                          >
                            RW :
                            {{
                              packet.mk_weight
                                ? packet.mk_weight
                                : packet.weight
                            }}
                          </th>
                          <th
                            colspan="2"
                            class="border m-auto border-black"
                            style="font-size: 9px;line-height: 8px;"
                            v-if="packet.mk_weight != null"
                          >
                            PW : {{ packet.polish_weight }}
                          </th>
                        </tr>
                        <tr class="text-center">
                          <th colspan="9" class="text-center">
                            <VueBarcode
                              :value="packet.barnum"
                              :options="{
                                displayValue: false,
                                height: 22,
                                width: 2
                              }"
                              tag="svg"
                            ></VueBarcode>
                          </th>
                        </tr>
                      </table>

                      <p
                        class="p-0 text-black w-full text-right"
                        style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"
                      >
                        <span class="text-right">{{ packet.stone }}</span> /
                        <span class="text-right">Kodllin TECHONOLOGY</span>
                      </p>
                    </div>
                  </div>
                  <div
                    id="print-all-barcods-ls"
                    class="absolute left-0 top-0 p-0 m-0"
                  >
                    <div
                      style="page-break-before: always;"
                      class="mt-2"
                      v-for="(packet, index) in ls_items"
                      :key="index"
                    >
                      <table class="p-0 w-full text-black" border="collapse">
                        <tr
                          class="text-center border border-l-0 border-r-0 border-black m-auto"
                        >
                          <th
                            colspan="3"
                            class="border m-auto border-black"
                            style="font-size: 9px;line-height: 8px;"
                          >
                            {{ packet.number }}
                          </th>
                          <th
                            colspan="2"
                            class="border m-auto border-black"
                            style="font-size: 9px;line-height: 8px;"
                          >
                            Lot : {{ packet.vepari_no }}
                          </th>
                          <th
                            colspan="2"
                            class="border m-auto border-black"
                            style="font-size: 9px;line-height: 8px;"
                          >
                            RW :
                            {{
                              packet.mk_weight
                                ? packet.mk_weight
                                : packet.weight
                            }}
                          </th>
                          <th
                            colspan="2"
                            class="border m-auto border-black"
                            style="font-size: 9px;line-height: 8px;"
                            v-if="packet.mk_weight != null"
                          >
                            PW : {{ packet.polish_weight }}
                          </th>
                        </tr>
                        <tr class="text-center">
                          <th colspan="9" class="text-center">
                            <VueBarcode
                              :value="packet.barnum"
                              :options="{
                                displayValue: false,
                                height: 22,
                                width: 2
                              }"
                              tag="svg"
                            ></VueBarcode>
                          </th>
                        </tr>
                      </table>

                      <p
                        class="p-0 text-black w-full text-right"
                        style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"
                      >
                        <span class="text-right">{{ packet.stone }}</span> /
                        <span class="text-right">Kodllin TECHONOLOGY</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
    <div
      class="grid grid-cols-12 mt-5"
      v-if="
        (edit_flag == 1 &&
          packet_details.main_transaction_status == 1 &&
          user_details.account_id == packet_details.manager_id &&
          user_details.user_role == packet_details.manager_type &&
          (packet_details.process_type == 2 || packet_details.process_type == 5))
      "
    >
      <div class="intro-y box col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y">
          <div
            class="flex flex-col sm:flex-row items-center p-5 py-3 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Last Process Details</h2>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5 w-full">
              <div class="col-span-12 md:col-span-4">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Packet No :&nbsp;
                  </label>
                  <label
                    for="update-profile-form-6 "
                    class="form-label font-bold"
                  >
                    {{ packet_details.number }}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Issue Lot weight :&nbsp;
                  </label>
                  <label
                    for="update-profile-form-6 "
                    class="form-label font-bold"
                  >
                    {{ packet_details.issue_weight }} Ct</label
                  >
                </div>

                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Issue To :&nbsp;
                  </label>
                  <label
                    for="update-profile-form-6 "
                    class="form-label font-bold"
                  >
                    {{
                      packet_details.employee_first_name +
                        ' ' +
                        packet_details.employee_last_name
                    }}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Issue At :&nbsp;
                  </label>
                  <label
                    for="update-profile-form-6 "
                    class="form-label font-bold"
                  >
                    {{ packet_details.created_at }}</label
                  >
                </div>
              </div>
              <div class="col-span-12 md:col-span-8">
                <div class="border w-full">
                  <div style="background-color:#fff;">
                    <div class="ag-theme-alpine" style="height: 300px; width: 100%;">
                      <ag-grid-vue
                        class="ag-theme-alpine"
                        :columnDefs="columnDefs"
                        :rowData="rowData"
                        :gridOptions="gridOptions"
                        @grid-ready="onGridReady"
                      />

                    </div>
                    <table class="w-full border">
                      <tbody>
                        <tr class="border-b bg-gray-100 dark:bg-dark-1">
                          <!-- <td></td> -->
                          <td colspan="2">
                            <button
                              class="btn btn-primary btn-sm py-1"
                              title="Add New Rough"
                              @click="createNewRough"
                            >
                              <PlusIcon class="h-4 w-4" /> Rough
                            </button>
                          </td>
                          <td
                            class="py-1 border-b text-xs lg:text-md text-center border-r"
                          >
                            0
                          </td>
                          <td
                            class="py-1 border-b text-xs lg:text-md text-center border-r"
                          >
                            0
                          </td>
                          <td
                            class="py-1 border-b text-xs lg:text-md text-center border-r"
                          ></td>
                          <td
                            class="py-1 border-b text-xs lg:text-md text-center border-r"
                          >
                            0
                          </td>
                          <td></td>
                        </tr>
                        <tr class="border-b bg-gray-100 dark:bg-dark-1">
                          <!-- <td></td> -->
                          <td colspan="2">
                            <button
                              class="btn btn-primary btn-sm py-1"
                              title="Add New Rough"
                              @click="createNewAssortment"
                            >
                              <PlusIcon class="h-4 w-4" /> Labour
                            </button>
                          </td>
                          <td
                            class="py-1 border-b text-xs lg:text-md text-center border-r"
                          >
                            {{ menual_total.weight }}
                          </td>
                          <td
                            class="py-1 border-b text-xs lg:text-md text-center border-r"
                          >
                            {{ menual_total.ls_weight }}
                          </td>
                          <td
                            class="py-1 border-b text-xs lg:text-md text-center border-r"
                          ></td>
                          <td
                            class="py-1 border-b text-xs lg:text-md text-center border-r"
                          >
                            {{ menual_total.ls_majuri }}
                          </td>
                          <td></td>
                        </tr>
                        <tr
                          class="border-b bg-gray-100 dark:bg-dark-1"
                          v-if="
                            user_details.account_id ==
                              packet_details.manager_id &&
                              user_details.user_role ==
                                packet_details.manager_type &&
                              packet_details.process_type == 2
                          "
                        >
                          <td colspan="1" class=""></td>
                          <td colspan="1" class="">Rejection</td>
                          <td
                            class="py-1 border-b text-xs lg:text-md text-center border-r"
                          >
                            <input
                              type="text"
                              class="form-control w-full py-1 text-center"
                              @keypress="$h.preventInvalidInput"
                              @input="getPlanningTotal"
                              v-model="menual_total.assortment_weight"
                            />
                          </td>

                          <td colspan="1" class="">Ghat Weight</td>
                          <td
                            class="py-1 border-b text-xs lg:text-md text-center border-r"
                          >
                            {{ menual_total.ghat_weight }}
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->

        <div class="intro-y">
          <div
            class="flex flex-col sm:flex-row items-center p-5 py-3 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto"></h2>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              @click="submitReturnRough"
            >
              Take Return
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 mt-5" v-if="edit_flag == 2">
      <div class="intro-y box col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y">
          <div
            class="flex flex-col sm:flex-row items-center p-5 py-3 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Last Process Details</h2>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5 w-full">
              <div class="col-span-12 md:col-span-4">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Packet No :&nbsp;
                  </label>
                  <label
                    for="update-profile-form-6 "
                    class="form-label font-bold"
                  >
                    {{ packet_details.number }}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Issue Lot weight :&nbsp;
                  </label>
                  <label
                    for="update-profile-form-6 "
                    class="form-label font-bold"
                  >
                    {{ edit_transaction.issue_weight }} Ct</label
                  >
                </div>

                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Issue To :&nbsp;
                  </label>
                  <label
                    for="update-profile-form-6 "
                    class="form-label font-bold"
                  >
                    {{
                      edit_transaction.employee_first_name +
                        ' ' +
                        edit_transaction.employee_last_name
                    }}</label
                  >
                </div>
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Issue At :&nbsp;
                  </label>
                  <label
                    for="update-profile-form-6 "
                    class="form-label font-bold"
                  >
                    {{ edit_transaction.issue_date }}</label
                  >
                </div>
              </div>
              <div class="col-span-12 md:col-span-8">
                <div class="border w-full">
                  <div style="background-color:#fff;">
                    <div class="ag-theme-alpine" style="height: 300px; width: 100%;">
                      <ag-grid-vue
                        class="ag-theme-alpine"
                        :columnDefs="columnDefs"
                        :rowData="rowData"
                        :gridOptions="gridOptions"
                        @grid-ready="onGridReady"
                      />

                    </div>
                    <table class="w-full border">
                      <tbody>
                        <tr class="border-b bg-gray-100 dark:bg-dark-1">
                          <td></td>
                          <td></td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r">{{ edit_total.weight }}</td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r">{{ edit_total.ls_weight }}
                          </td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r"></td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r">{{ edit_total.ls_majuri }}
                          </td>

                        </tr>
                        <tr class="border-b bg-gray-100 dark:bg-dark-1">
                          <td colspan="1" class=""></td>
                          <td colspan="1" class="">Assortment Weight</td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text"
                              class="form-control w-full py-1 text-center" @keypress="$h.preventInvalidInput"
                              @input="getEditTotal" v-model="edit_total.assortment_weight"></td>
                          <td colspan="1" class="">Ghat Weight</td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r">{{edit_total.ghat_weight}}</td>
                          <td class="py-1 border-b text-xs lg:text-md text-center border-r" v-if="edit_transaction.status == 2">
                            <button class="btn btn-danger p-2 btn-sm" @click="resetLsDetails">Reset</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->

        <div class="intro-y">
          <div
            class="flex flex-col sm:flex-row items-center p-5 py-3 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto"></h2>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              @click="submitReturnRough"
            >
              Take Return
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 mt-5" v-if="ls_transactions.length > 0">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center justify-between p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">LS Transactions</h2>
          </div>

          <div class="p-1 pb-10">
            <div class="col-span-12">
              <div style="background-color:#fff;">
                <table class="mt-3 w-full">
                  <thead>
                    <tr class="border-b bg-gray-200 border dark:bg-dark-1">
                      <th class="py-2 text-center border">Transaction No</th>
                      <th class="py-2 text-center border">Employee</th>
                      <th class="py-2 text-center border">Issue Weight</th>
                      <th class="py-2 text-center border">Receive Weight</th>
                      <th class="py-2 text-center border">Loss Weight</th>
                      <th class="py-2 text-center border">Assortment</th>
                      <th class="py-2 text-center border">Net Assortment</th>
                      <th class="py-2 text-center border">Total Majuri</th>
                      <th class="py-2 text-center border">Issue Date</th>
                      <th class="py-2 text-center border">Status</th>
                      <th class="py-2 text-center border w-28">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(packet, index) in ls_transactions"
                      :key="index"
                      class="border hover:bg-gray-200 p-0 cursor-pointer"
                    >
                      <td class="py-1 font-medium text-center border">
                        {{ packet.transaction_code }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.employee_first_name }}
                        {{ packet.employee_last_name }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.issue_weight }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.receive_weight }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.weight_loss }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.assortment_weight }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.net_assortment_weight }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.issue_date }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        {{ packet.emp_majuri }}
                      </td>
                      <td class="py-1 font-medium text-center border">
                        <span
                          class="text-center text-primary-1"
                          v-if="packet.status == 1"
                        >
                          In Process</span
                        >
                        <span
                          class="text-center text-primary-4"
                          v-if="packet.status == 2"
                        >
                          Received</span
                        >
                        <span
                          class="text-center text-primary-3"
                          v-if="packet.status == 3"
                        >
                          Cancelled</span
                        >
                      </td>
                      <td>
                        <button
                          class="btn btn-primary btn-sm p-2"
                          @click="editTransaction(packet)"
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
    <BarcodeSingleStone ref="BarcodeSingleStone" />
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent } from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker'
import Tabulator from 'tabulator-tables'
// import BarcodeGenerator from "@/components/barcode-generator/Main.vue";
import BarcodeSingleStone from '@/components/barcodes/Main.vue'
import {
    AgGridVue
} from "ag-grid-vue3";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

export default {
  components: {
    DarkModeSwitcher,
    datetime,
    BarcodeSingleStone,
    AgGridVue
  },
  props: {
    rough_id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      packet_details: [],
      stock_items: [],
      ls_items: [],
      ls_transactions: [],
      rough_items: [],
      assortment_items: [],
      edit_flag: 1,
      edit_transaction: [],

      rough_cnt: 0,
      user_details: [],
      menual_total: {
        rough_weight: 0,
        total_return_weight: 0,
        weight: 0,
        polish_weight: 0,
        value: 0,
        return_weight: 0,
        ghat_weight: 0,
        assortment_weight: 0,
        ls_weight: 0,
        ls_majuri: 0
      },
      edit_total: {
        rough_weight: 0,
        total_return_weight: 0,
        weight: 0,
        polish_weight: 0,
        value: 0,
        return_weight: 0,
        ghat_weight: 0,
        assortment_weight: 0,
        ls_weight: 0,
        ls_majuri: 0
      },
      last_pack_id: '',
      scanload: false,
      scan_lot_no: '',
      rowData: null,
      gridOptions: {
        defaultColDef: {
          sortable: false,
          filter: false,
        },
      },
      columnDefs: [
        { headerName: 'Packet No', field: 'packet_no', width: 150, },
        {
          headerName: 'Rough Weight',
          field: 'rough_weight',
          editable: true,
          width: 150,
          valueSetter: params => {
            if (params.newValue == '') {
              params.data.rough_weight = params.newValue
            } else {
              if (parseFloat(params.newValue) > 0) {
              } else {
                this.$swal('Warning!', 'Invalid Weight' + ' Row!', 'warning')
                return false
              }

              let total_value =
                parseFloat(params.newValue) +
                parseFloat(params.data.assort_weight)

              if (total_value > params.data.cr_weight) {
                this.$swal(
                  'Warning!',
                  'Assort Weight Must be less then Issue Weight' + ' Row!',
                  'warning'
                )
                return false
              }
              params.data.rough_weight = params.newValue

              if (this.edit_flag == 1) {
                if (params.node.rowIndex == this.rowData.length - 1) {
                  this.createNewRough()

                  setTimeout(() => {
                    this.gridOptions.api.setFocusedCell(params.node.rowIndex + 1, 'rough_weight');
                  }, 100);
                } else {

                  setTimeout(() => {
                    this.gridOptions.api.setFocusedCell(params.node.rowIndex + 1, 'rough_weight');
                  }, 100);
                }
              } else {
              }

              if(this.edit_flag == 1) {

                this.getPlanningTotal()
              } else {

                this.getEditTotal()
              }
            }
          }
        },
        {
          headerName: 'Cal Weight',
          field: 'cal_weight',
          editable: true,
          width: 150,
          valueSetter: params => {
            if (params.newValue == '') {
              params.data.cal_weight = params.newValue
              setTimeout(() => {

                this.getMajuri_details(
                  this.rowData[params.node.rowIndex],
                  params.node.rowIndex
                )
              }, 200);
            } else {
              if (parseFloat(params.newValue) > 0) {
              } else {
                this.$swal('Warning!', 'Invalid Weight' + ' Row!', 'warning')
                return false
              }

              if (this.edit_flag == 1) {
                if (params.node.rowIndex == this.rowData.length - 1) {
                  this.createNewRough()

                  setTimeout(() => {
                    this.gridOptions.api.setFocusedCell(params.node.rowIndex + 1, 'cal_weight');
                  }, 100);
                } else {

                  setTimeout(() => {
                    this.gridOptions.api.setFocusedCell(params.node.rowIndex + 1, 'cal_weight');
                  }, 100);
                }
              } else {
              }

              params.data.cal_weight = params.newValue

              setTimeout(() => {

                this.getMajuri_details(
                  this.rowData[params.node.rowIndex],
                  params.node.rowIndex
                )
              }, 200);
            }
          }
        },
        {
          headerName: 'Type',
          field: 'type',
          width: 150,
          editable: true,
          cellStyle: { borderRight: '1px solid #ccc' },
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: ['Pie', 'Showing']
          },
          valueSetter: params => {
            if (params.newValue == '') {
              console.log(params)
              params.data.type = params.newValue
              setTimeout(() => {

                this.getMajuri_details(
                  this.rowData[params.node.rowIndex],
                  params.node.rowIndex
                )
              }, 200);
            } else {
              params.data.type = params.newValue

              if (this.edit_flag == 1) {
                if (params.node.rowIndex == this.rowData.length - 1) {
                  this.createNewRough()

                  setTimeout(() => {
                    this.gridOptions.api.setFocusedCell(params.node.rowIndex + 1, 'type');
                  }, 100);
                } else {

                  setTimeout(() => {
                    this.gridOptions.api.setFocusedCell(params.node.rowIndex + 1, 'type');
                  }, 100);
                }
              } else {
              }

              setTimeout(() => {

                this.getMajuri_details(
                  this.rowData[params.node.rowIndex],
                  params.node.rowIndex
                )
              }, 200);
            }
          }
        },
        { headerName: 'Majuri', field: 'majuri', width: 100 },
        {
          headerName: 'Action',
          field: 'action',
          width: 80,
          cellRenderer: params => {
            if(params.node.rowIndex != 0) {

              const button = document.createElement('button')
              button.type = 'button'
              button.classList.add(
                'btn',
                'btn-danger',
                'icon-danger',
                'btn-sm',
                'p-1'
              )
              button.innerHTML = '<i class="fa fa-trash p-1"></i>'
              button.addEventListener('click', () => {
                this.$swal.fire({
                  title: 'Are you sure?',
                  text: 'You are about to Remove Record!',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Confirm',
                  showLoaderOnConfirm: true,
                  cancelButtonText: 'Cancel',
                  preConfirm: () => {
                    return true
                  },
                  allowOutsideClick: () => !Swal.isLoading()
                }).then(result => {
                  if (result.isConfirmed) {
                    this.rowData.splice(params.node.rowIndex, 1)
                    this.gridOptions.api.setRowData(this.rowData);
                    this.getPlanningTotal();
                  }
                })
              })
              return button
            } else {

              const button = document.createElement('button')
              button.type = 'button'
              button.classList.add(
                'btn',
                'hidden',
                'icon-danger',
                'btn-sm',
                'p-1'
              )
              return button
            }
          }
        }
      ],
    };
  },
  computed: {},
  mounted() {
    this.user_details = this.$store.state.user.currentUser
  },
  methods: {
    setPrintData(item) {
      this.$refs.BarcodeSingleStone.setPrintData({
        id: item.id,
        vepari_no: item.vepari_no,
        number: item.number,
        type: item.type,
        mk_weight: item.mk_weight,
        weight: item.weight,
        polish_weight: item.polish_weight,
        barnum: item.barnum,
        shape: item.shape ?? "",
        color: item.color ?? "",
        purity: item.purity ?? "",
        cut: item.cut ?? "",
        polish: item.polish ?? "",
        symmetry: item.symmetry ?? "",
        fluerocent: item.fluerocent ?? "",
        tension: item.stone ?? "",
        stone: item.tension ?? "",
        length: item.length,
        width: item.width,
        height: item.height,
        date: item.created_at,
      })
    },
    setPrintAllData(items) {
      let data = [];
      items.forEach(element => {
        data.push({
          id: element.id,
          vepari_no: element.vepari_no,
          number: element.number,
          type: element.type,
          stock_type: element.stock_type,
          mk_weight: element.mk_weight,
          weight: element.weight,
          polish_weight: element.polish_weight,
          barnum: element.barnum,
          shape: (element.shape ?? ""),
          color: (element.color ?? ""),
          purity: (element.purity ?? ""),
          cut: (element.cut ?? ""),
          polish: (element.polish ?? ""),
          symmetry: (element.symmetry ?? ""),
          fluerocent: (element.fluerocent ?? ""),
          tension: (element.tension ?? ""),
          stone: (element.stone ?? ""),
          length: element.length,
          width: element.width,
          height: element.height,
          date: element.created_at,
        })

      });
      this.$refs.BarcodeSingleStone.setPrintAllData(data)
    },
    onGridReady(params) {
      this.gridOptions.api = params.api;
    },
    async scanAndAddPack() {
      let _this = this
      _this.clearSearch(this.scan_lot_no)
      this.scanload = true

      //get lot details
      const data = {
        barcode: this.scan_lot_no
      }
      let url_post = ''
      if (this.scan_lot_no != '') {
        url_post = 'api/process/get_packet_details_for_recive_ls_new'
      } else {
        url_post = 'api/process/get_packet_details_for_recive_ls_new'
      }
      let promise = axios({
        url: url_post,
        method: 'post',
        data: data,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          this.scanload = false
          this.packet_details = data.packet_details
          this.stock_items = data.stock_items
          this.rough_items = []
          this.assortment_items = []

          this.rowData = [];
          this.rough_cnt += 1
          let new_arr = {
            id: this.rough_cnt,
            record_type: 1,
            packet_no: this.packet_details.number,
            packet_id: this.packet_details.id,
            rough_weight: this.packet_details.issue_weight,
            cal_weight: this.packet_details.issue_weight,
            type: '',
            majuri: ''
          }

          this.rowData.push(new_arr)
          if((this.edit_flag == 1 &&
          this.packet_details.main_transaction_status == 1 &&
          this.user_details.account_id == this.packet_details.manager_id &&
          this.user_details.user_role == this.packet_details.manager_type &&
          (this.packet_details.process_type == 2 || this.packet_details.process_type == 5))) {

            setTimeout(() => {

              this.gridOptions.api.setRowData(this.rowData);
                this.gridOptions.api.ensureIndexVisible(this.rowData.length - 1);
                this.gridOptions.api.setFocusedCell(0, 'rough_weight');
                this.gridOptions.api.startEditingCell({
                  rowIndex: 0,
                  colKey: 'rough_weight',
                });
            }, 200);
          }
          // R01-137-31

          this.getLsDetails(data.stock_items[0].id)
        })
        .catch(_error => {
          this.scanload = false
          return []
        })
    },
    async getLsDetails(id) {
      let _this = this
      //get lot details
      const data = {
        packet_id: id,
        ip: localStorage.getItem('sk_key')
      }
      let promise = axios({
        url: 'api/edit/get_ls_transactions',
        method: 'post',
        data: data,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
      promise
        .then(result => result.data)
        .then(data => {
          if (data.status == 'error') {
            this.ls_transactions = []
          } else {
            this.ls_transactions = data.items
          }
        })
        .catch(_error => {})
    },
    editTransaction(item) {
      this.edit_flag = 2
      this.edit_transaction = item

      this.rowData = []
      // Setup New Array
      this.edit_transaction.return_record[0].forEach(element => {
        this.rowData.push({
          id: element.id,
          record_type: 1,
          packet_no: element.packet_no,
          packet_id: element.packet_id,
          rough_weight: element.rough_weight,
          cal_weight: element.cal_weight,
          type: (element.type == 1 ? "Pie" : (element.type == 2 ? "Showing" : "")),
          majuri: element.majuri
        })
      })
      this.edit_transaction.return_record[1].forEach(element => {
        this.rowData.push({
          id: element.id,
          record_type: 2,
          packet_no: element.packet_no,
          packet_id: element.packet_id,
          rough_weight: element.rough_weight,
          cal_weight: element.cal_weight,
          type: (element.type == 1 ? "Pie" : (element.type == 2 ? "Showing" : "")),
          majuri: element.majuri
        })
      })
      this.getEditTotal()
      //  this.gridOptions.api.setRowData(this.rowData)
    },
    createNewAssortment() {
      this.rough_cnt += 1
      let new_arr = {
        id: this.rough_cnt,
        record_type: 2,
        packet_no: 'Labour',
        packet_id: '',
        rough_weight: '',
        cal_weight: '',
        type: '',
        majuri: ''
      }

      this.rowData.push(new_arr)
      this.gridOptions.api.setRowData(this.rowData);
    },
    createNewRough() {
      this.rough_cnt += 1
      let new_arr = {
        id: this.rough_cnt,
        record_type: 1,
        packet_no: 'New Part',
        packet_id: '',
        rough_weight: '',
        cal_weight: '',
        type: '',
        majuri: ''
      }

      this.rowData.push(new_arr)
      this.gridOptions.api.setRowData(this.rowData);
    },
    removeField(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'You are about to delete a Record!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        cancelButtonText: 'Cancel',

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          var i = this.rowData.length
          while (i--) {
            if (this.rowData[i]['id'] == id) {
              this.rowData.splice(i, 1)
            }
          }
          this.getPlanningTotal()
        }
      })
    },
    removeAssortmentField(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'You are about to delete a Record!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        cancelButtonText: 'Cancel',

        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          var i = this.assortment_items.length
          while (i--) {
            if (this.assortment_items[i]['id'] == id) {
              this.assortment_items.splice(i, 1)
            }
          }
          this.getPlanningTotal()
        }
      })
    },
    submitReturnRough() {
      let submit = true

      this.rough_items = []
      this.assortment_items = []

      // Split the  Records

      if (this.edit_flag == 1) {
        this.rowData.forEach(element => {
          if (element.record_type == 1) {
            this.rough_items.push({
              id: element.id,
              record_type: element.record_type,
              packet_no: element.packet_no,
              packet_id: element.packet_id,
              rough_weight: element.rough_weight,
              cal_weight: element.cal_weight,
              type: (element.type == "Pie" ? 1 : (element.type == "Showing" ? 2 : "")),
              majuri: element.majuri,
            })
          } else {
            this.assortment_items.push({
              id: element.id,
              record_type: element.record_type,
              packet_no: element.packet_no,
              packet_id: element.packet_id,
              rough_weight: element.rough_weight,
              cal_weight: element.cal_weight,
              type: (element.type == "Pie" ? 1 : (element.type == "Showing" ? 2 : "")),
              majuri: element.majuri,
            })
          }
        })

        if (!this.rough_items[this.rough_items.length - 1].rough_weight &&  !this.rough_items[this.rough_items.length - 1].cal_weight) {
          this.rough_items.splice(this.rough_items.length - 1, 1)
        }

        this.rough_items.forEach((plan, index) => {
          if (!plan.rough_weight) {
            this.$swal('Warning!', 'LS Weight is Required !', 'warning')
            submit = false
          }
          if (!plan.cal_weight && this.rough_items.length - 1 != index) {
            this.$swal('Warning!', 'LS Weight is Required !', 'warning')
            submit = false
          }
          if (!plan.type && this.rough_items.length - 1 != index) {
            this.$swal('Warning!', 'Receive Type is Required !', 'warning')
            submit = false
          }
        })
        this.assortment_items.forEach((plan, index) => {
          if (!plan.cal_weight) {
            this.$swal('Warning!', 'LS Weight is Required !', 'warning')
            submit = false
          }
          if (!plan.type) {
            this.$swal('Warning!', 'Receive Type is Required !', 'warning')
            submit = false
          }
        })
      } else {
        this.edit_transaction.return_record[0] = []
        this.edit_transaction.return_record[1] = []
        this.rowData.forEach(element => {
          if (element.record_type == 1) {
            this.edit_transaction.return_record[0].push({
              id: element.id,
              record_type: element.record_type,
              packet_no: element.packet_no,
              packet_id: element.packet_id,
              rough_weight: element.rough_weight,
              cal_weight: element.cal_weight,
              type: (element.type == "Pie" ? 1 : (element.type == "Showing" ? 2 : "")),
              majuri: element.majuri,
            })
          } else {
            this.edit_transaction.return_record[1].push({
              id: element.id,
              record_type: element.record_type,
              packet_no: element.packet_no,
              packet_id: element.packet_id,
              rough_weight: element.rough_weight,
              cal_weight: element.cal_weight,
              type: (element.type == "Pie" ? 1 : (element.type == "Showing" ? 2 : "")),
              majuri: element.majuri,
            })
          }
        })

        this.edit_transaction.return_record[0].forEach((plan, index) => {
          if (!plan.rough_weight) {
            this.$swal('Warning!', 'New Rough Weight is Required !', 'warning')
            submit = false
          }
          if (!plan.type && this.edit_transaction.length - 1 != index) {
            this.$swal('Warning!', 'Receive Type is Required !', 'warning')
            submit = false
          }
        })
        this.edit_transaction.return_record[1].forEach((plan, index) => {
          if (!plan.cal_weight) {
            this.$swal('Warning!', 'LS Weight is Required !', 'warning')
            submit = false
          }
          if (!plan.type) {
            this.$swal('Warning!', 'Receive Type is Required !', 'warning')
            submit = false
          }
        })
      }
      if (submit == true) {
        this.$swal({
          title: 'Are you sure?',
          text: 'You are You Want To Return!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Submit',
          showLoaderOnConfirm: true,
          cancelButtonText: 'Cancel',

          allowOutsideClick: () => !$Swal.isLoading()
        }).then(result => {
          if (result.isConfirmed) {
            if (this.edit_flag == 1) {
              let params = {
                menual_total: this.menual_total,
                rough_items: this.rough_items,
                assortment_items: this.assortment_items,
                packet_id: this.packet_details.id,
                trans_id: this.packet_details.transaction_id,
                ip: localStorage.getItem('sk_key')
              }

              let api_url = ''

              if (this.packet_details.process_type == 2) {
                api_url = 'api/process/return_ls_receive_new'
              } else if (this.packet_details.process_type == 5) {
                api_url = 'api/process/return_ls_receive_party'
              }
              let promise = axios({
                url: api_url,
                method: 'post',
                data: params,
                baseURL: BASE_URL,
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                  headers: {
                    Accept: 'application/json'
                  }
                }
              })
              return promise
                .then(result => result.data)
                .then(data => {
                  if (data.status == 'success') {
                    this.$swal(
                      'Success!',
                      'Item Returned to Manager.',
                      'success'
                    )
                    this.scanAndAddPack()
                    this.ls_items = data.items

                    if (this.ls_items.length > 0) {
                      setTimeout(() => {
                        document.querySelector('[id="print-ls-stones"]').click()
                      }, 200)
                    }
                  } else {
                    this.$swal('Warning!', 'Somthing Wants Wrong!', 'warning')
                  }
                })
            } else {
              let params = {
                menual_total: this.edit_total,
                rough_items: this.edit_transaction.return_record,
                packet_id: this.packet_details.id,
                trans_id: this.edit_transaction.id,
                action_type: 1,
                ip: localStorage.getItem('sk_key'),
                type: 'create'
              }

              let api_url = ''

              if (this.edit_transaction.process_type == 2) {
                api_url = 'api/edit/edit_or_reset_ls_packets'
              } else if (this.edit_transaction.process_type == 5) {
                api_url = 'api/edit/edit_or_reset_ls_packets'
              }
              let promise = axios({
                url: api_url,
                method: 'post',
                data: params,
                baseURL: BASE_URL,
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                  headers: {
                    Accept: 'application/json'
                  }
                }
              })
              return promise
                .then(result => result.data)
                .then(data => {
                  if (data.status == 'success') {
                    this.$swal(
                      'Success!',
                      'Item Returned to Manager.',
                      'success'
                    )
                    this.scanAndAddPack()
                    this.ls_items = data.items

                    if (this.ls_items.length > 0) {
                      setTimeout(() => {
                        document.querySelector('[id="print-ls-stones"]').click()
                      }, 200)
                    }
                  } else {
                    if (data.type == 'warning' && data.type) {
                      this.submitReturnRoughConfirm(data.message)
                    } else {
                      this.$swal('Warning!', data.message, 'warning')
                    }
                  }
                })
            }
          }
        })
      }
    },
    submitReturnRoughConfirm(message) {
      let submit = true

      if (submit == true) {
        this.$swal({
          title: 'Are you sure?',
          text: message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Submit',
          showLoaderOnConfirm: true,
          cancelButtonText: 'Cancel',

          allowOutsideClick: () => !$Swal.isLoading()
        }).then(result => {
          if (result.isConfirmed) {
            let params = {
              menual_total: this.edit_total,
              rough_items: this.edit_transaction.return_record,
              packet_id: this.packet_details.id,
              trans_id: this.edit_transaction.id,
              action_type: 1,
              type: 'confirm',
              ip: localStorage.getItem('sk_key')
            }

            let api_url = ''

            if (this.edit_transaction.process_type == 2) {
              api_url = 'api/edit/edit_or_reset_ls_packets'
            } else if (this.edit_transaction.process_type == 5) {
              api_url = 'api/edit/edit_or_reset_ls_packets'
            }
            let promise = axios({
              url: api_url,
              method: 'post',
              data: params,
              baseURL: BASE_URL,
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
              },
              config: {
                headers: {
                  Accept: 'application/json'
                }
              }
            })
            return promise
              .then(result => result.data)
              .then(data => {
                if (data.status == 'success') {
                  this.$swal('Success!', 'Item Returned to Manager.', 'success')
                  this.scanAndAddPack()
                  this.ls_items = data.items

                  if (this.ls_items.length > 0) {
                    setTimeout(() => {
                      document.querySelector('[id="print-ls-stones"]').click()
                    }, 200)
                  }
                } else {
                  this.$swal('Warning!', 'Somthing Wants Wrong!', 'warning')
                }
              })
          }
        })
      }
    },
    resetLsDetails() {
      let submit = true

      if (submit == true) {
        this.$swal({
          title: 'Are you sure?',
          text: 'You want to reset ls details',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Submit',
          showLoaderOnConfirm: true,
          cancelButtonText: 'Cancel',

          allowOutsideClick: () => !$Swal.isLoading()
        }).then(result => {
          if (result.isConfirmed) {
            let params = {
              menual_total: this.edit_total,
              rough_items: this.edit_transaction.return_record,
              packet_id: this.packet_details.id,
              trans_id: this.edit_transaction.id,
              action_type: 2,
              ip: localStorage.getItem('sk_key'),
              type: 'create'
            }

            let api_url = ''

            if (this.edit_transaction.process_type == 2) {
              api_url = 'api/edit/edit_or_reset_ls_packets'
            } else if (this.edit_transaction.process_type == 5) {
              api_url = 'api/edit/edit_or_reset_ls_packets'
            }
            let promise = axios({
              url: api_url,
              method: 'post',
              data: params,
              baseURL: BASE_URL,
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
              },
              config: {
                headers: {
                  Accept: 'application/json'
                }
              }
            })
            return promise
              .then(result => result.data)
              .then(data => {
                if (data.status == 'success') {
                  this.$swal('Success!', 'Item Returned to Manager.', 'success')
                  this.scanAndAddPack()
                  this.ls_items = data.items

                  if (this.ls_items.length > 0) {
                    setTimeout(() => {
                      document.querySelector('[id="print-ls-stones"]').click()
                    }, 200)
                  }
                } else {
                  if (data.type == 'warning' && data.type) {
                    this.resetLsDetailsConfirm(data.message)
                  } else {
                    this.$swal('Warning!', data.message, 'warning')
                  }
                }
              })
          }
        })
      }
    },
    resetLsDetailsConfirm(message) {
      let submit = true

      if (submit == true) {
        this.$swal({
          title: 'Are you sure?',
          text: message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Submit',
          showLoaderOnConfirm: true,
          cancelButtonText: 'Cancel',

          allowOutsideClick: () => !$Swal.isLoading()
        }).then(result => {
          if (result.isConfirmed) {
            let params = {
              menual_total: this.edit_total,
              rough_items: this.edit_transaction.return_record,
              packet_id: this.packet_details.id,
              trans_id: this.edit_transaction.id,
              action_type: 2,
              ip: localStorage.getItem('sk_key'),
              type: 'confirm'
            }

            let api_url = ''

            if (this.edit_transaction.process_type == 2) {
              api_url = 'api/edit/edit_or_reset_ls_packets'
            } else if (this.edit_transaction.process_type == 5) {
              api_url = 'api/edit/edit_or_reset_ls_packets'
            }
            let promise = axios({
              url: api_url,
              method: 'post',
              data: params,
              baseURL: BASE_URL,
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
              },
              config: {
                headers: {
                  Accept: 'application/json'
                }
              }
            })
            return promise
              .then(result => result.data)
              .then(data => {
                if (data.status == 'success') {
                  this.$swal('Success!', 'Item Returned to Manager.', 'success')
                  this.scanAndAddPack()
                  this.ls_items = data.items

                  if (this.ls_items.length > 0) {
                    setTimeout(() => {
                      document.querySelector('[id="print-ls-stones"]').click()
                    }, 200)
                  }
                } else {
                  if (data.type == 'warning' && data.type) {
                    this.submitReturnRoughConfirm(data.message)
                  } else {
                    this.$swal('Warning!', data.message, 'warning')
                  }
                }
              })
          }
        })
      }
    },
    clearSearch(lot_no) {
      this.scan_lot_no = lot_no
      this.packet_details = []
      this.stock_items = []
      this.rough_packets = []
      this.rowData = []
      this.menual_total.weight = 0
      this.menual_total.return_weight = 0
      this.menual_total.polish_weight = 0
      this.menual_total.part_weight = 0
      this.menual_total.rough_weight = 0
      this.menual_total.value = 0
      this.menual_total.ghat_weight = 0
      this.menual_total.assortment_weight = 0
      this.menual_total.ls_majuri = 0
      this.menual_total.ls_weight = 0
      this.edit_total.weight = 0
      this.edit_total.return_weight = 0
      this.edit_total.polish_weight = 0
      this.edit_total.part_weight = 0
      this.edit_total.rough_weight = 0
      this.edit_total.value = 0
      this.edit_total.ghat_weight = 0
      this.edit_total.assortment_weight = 0
      this.edit_total.ls_majuri = 0
      this.edit_total.ls_weight = 0
      this.edit_flag = 1
      this.scanload = false
      this.showreturndetails = false
      this.enable_return = false
    },
    update_print_status(item) {
      let params = {
        packet_id: window.btoa(item.id),
        ip: localStorage.getItem('sk_key')
      }

      axios({
        url: 'api/process/update_print_status',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
        .then(res => {
          if (res.data.status == 'error') {
            this.$swal('Warning!', res.data.message, 'warning')
          } else if (res.data.status == 'success') {
            item.print_status = false
          }
        })
        .catch(err => {
          this.loading = false
          this.$swal('Warning!', 'Something went wrong.', 'warning')
        })
    },
    getMajuri_details(item, index) {
      if (item.type != '') {
        let params = {
          id: this.edit_transaction.id,
          weight: item.cal_weight,
          type: (item.type == "Pie" ? 1 : 2),
          packet_id: (this.edit_flag == 1 ? this.packet_details.id : item.packet_id),
          ip: localStorage.getItem('sk_key')
        }
        axios({
          url: 'api/process/get_ls_majuri_details',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          config: {
            headers: {
              Accept: 'application/json'
            }
          }
        }).then(res => {
          let rate = res.data.rate

          if (rate) {
            item.majuri = parseFloat(rate * item.cal_weight).toFixed(2)

            if(this.edit_flag == 1) {

              this.getPlanningTotal()
            } else {

              this.getEditTotal()
            }
            setTimeout(() => {

              this.gridOptions.api.setRowData(this.rowData);
            }, 200);
          } else  {
            item.majuri = 0

            if(this.edit_flag == 1) {

              this.getPlanningTotal()
            } else {

              this.getEditTotal()
            }
            setTimeout(() => {

              this.gridOptions.api.setRowData(this.rowData);
            }, 200);

          }
        })
      } else {
        item.majuri = 0
        if(this.edit_flag == 1) {

          this.getPlanningTotal()
        } else {

          this.getEditTotal()
        }
      }
    },
    getPlanningTotal() {
      this.menual_total.weight = 0
      this.menual_total.return_weight = 0
      this.menual_total.ls_weight = 0
      this.menual_total.ls_majuri = 0

      if (this.packet_details.process_type == 2) {
        for (let i = 0; i < this.rowData.length; i++) {
          if (i == length - 1) {
            this.last_pack_id = this.rowData[i]['id']
          }

          this.menual_total.weight = parseFloat(
            parseFloat(this.menual_total.weight) +
              parseFloat(
                this.rowData[i]['rough_weight']
                  ? this.rowData[i]['rough_weight']
                  : 0
              )
          ).toFixed(4)
          this.menual_total.return_weight = parseFloat(
            parseFloat(this.menual_total.return_weight) +
              parseFloat(
                this.rowData[i]['rough_weight']
                  ? this.rowData[i]['rough_weight']
                  : 0
              )
          ).toFixed(4)
          this.menual_total.ls_weight = parseFloat(
            parseFloat(this.menual_total.ls_weight) +
              parseFloat(
                this.rowData[i]['cal_weight']
                  ? this.rowData[i]['cal_weight']
                  : 0
              )
          ).toFixed(4)
          this.menual_total.ls_majuri = parseFloat(
            parseFloat(this.menual_total.ls_majuri) +
              parseFloat(
                this.rowData[i]['majuri'] ? this.rowData[i]['majuri'] : 0
              )
          ).toFixed(4)

          if (
            parseFloat(this.menual_total.weight) >
            parseFloat(this.packet_details.issue_weight)
          ) {
            this.menual_total.weight = parseFloat(
              parseFloat(this.menual_total.weight) +
                parseFloat(
                  this.rowData[i]['rough_weight']
                    ? this.rowData[i]['rough_weight']
                    : 0
                )
            ).toFixed(4)
            this.rowData[i]['rough_weight'] = ''
            this.$swal(
              'Warning!',
              'Return Weight Grater then issue Weight!',
              'warning'
            )
            this.getPlanningTotal()
            return
          }
        }

        if (
          parseFloat(
            parseFloat(this.menual_total.weight) +
              parseFloat(this.menual_total.assortment_weight)
          ) > parseFloat(this.packet_details.issue_weight)
        ) {
          this.menual_total.assortment_weight = ''
          this.menual_total.ghat_weight = ''
          this.$swal(
            'Warning!',
            'Return Weight Grater then issue Weight!',
            'warning'
          )
          this.getPlanningTotal()
          return
        }

        if (
          parseFloat(
            parseFloat(this.menual_total.weight) +
              parseFloat(this.menual_total.assortment_weight) +
              parseFloat(this.menual_total.rough_weight)
          ) > parseFloat(this.packet_details.issue_weight)
        ) {
          this.menual_total.assortment_weight = ''
          this.menual_total.ghat_weight = ''
          this.menual_total.rough_weight = ''
          this.$swal(
            'Warning!',
            'Return Weight Grater then issue Weight!',
            'warning'
          )
          this.getPlanningTotal()
          return
        }

        this.menual_total.ghat_weight = parseFloat(
          (this.packet_details.issue_weight
            ? this.packet_details.issue_weight
            : 0) -
            (parseFloat(
              this.menual_total.weight ? this.menual_total.weight : 0
            ) +
              parseFloat(
                this.menual_total.rough_weight
                  ? this.menual_total.rough_weight
                  : 0
              ) +
              parseFloat(
                this.menual_total.assortment_weight
                  ? this.menual_total.assortment_weight
                  : 0
              ))
        ).toFixed(4)

        this.menual_total.return_weight = parseFloat(
          parseFloat(this.menual_total.return_weight) +
            parseFloat(
              this.menual_total.rough_weight
                ? this.menual_total.rough_weight
                : 0
            )
        ).toFixed(4)
      }
    },
    getEditTotal() {

this.edit_total.weight = 0
this.edit_total.return_weight = 0
this.edit_total.ls_weight = 0
this.edit_total.ls_majuri = 0

if (this.edit_transaction.process_type == 2) {

  for (let i = 0; i < this.rowData.length; i++) {
    if (i == (length - 1)) {
      this.last_pack_id = this.rowData[i]['id']
    }

    this.edit_total.weight = parseFloat(parseFloat(this.edit_total.weight) + parseFloat(this.rowData[i]['rough_weight'] ? this.rowData[i]['rough_weight'] : 0)).toFixed(4)
    this.edit_total.return_weight = parseFloat(parseFloat(this.edit_total.return_weight) + parseFloat(this.rowData[i]['rough_weight'] ? this.rowData[i]['rough_weight'] : 0)).toFixed(4)
    this.edit_total.ls_weight = parseFloat(parseFloat(this.edit_total.ls_weight) + parseFloat(this.rowData[i]['cal_weight'] ? this.rowData[i]['cal_weight'] : 0)).toFixed(4)
    this.edit_total.ls_majuri = parseFloat(parseFloat(this.edit_total.ls_majuri) + parseFloat(this.rowData[i]['majuri'] ? this.rowData[i]['majuri'] : 0)).toFixed(4)

    if (parseFloat(this.edit_total.weight) > parseFloat(this.edit_transaction.issue_weight)) {
      this.edit_total.weight = parseFloat(parseFloat(this.edit_total.weight) + parseFloat(this.rowData[i]['rough_weight'] ? this.rowData[i]['rough_weight'] : 0)).toFixed(4)
      this.rowData[i]['rough_weight'] = '';
      this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
      this.getEditTotal()
      return;
    }

  }

  if (parseFloat(parseFloat(this.edit_total.weight) + parseFloat(this.edit_total.assortment_weight)) > parseFloat(this.edit_transaction.issue_weight)) {
    this.edit_total.assortment_weight = ''
    this.edit_total.ghat_weight = ''
    this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
    this.getEditTotal()
    return;
  }

  if (parseFloat(parseFloat(this.edit_total.weight) + parseFloat(this.edit_total.assortment_weight) + parseFloat(this.edit_total.rough_weight)) > parseFloat(this.edit_transaction.issue_weight)) {
    this.edit_total.assortment_weight = ''
    this.edit_total.ghat_weight = ''
    this.edit_total.rough_weight = ''
    this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
    this.getEditTotal()
    return;
  }

  this.edit_total.ghat_weight = parseFloat((this.edit_transaction.issue_weight ? this.edit_transaction.issue_weight : 0) - (parseFloat((this.edit_total.weight ? this.edit_total.weight : 0)) + parseFloat((this.edit_total.rough_weight ? this.edit_total.rough_weight : 0)) + parseFloat((this.edit_total.assortment_weight ? this.edit_total.assortment_weight : 0)))).toFixed(4)

  this.edit_total.return_weight = parseFloat(parseFloat(this.edit_total.return_weight) + parseFloat(this.edit_total.rough_weight ? this.edit_total.rough_weight : 0)).toFixed(4)

}

},
  },

};
</script>

<style scoped>
.ag-theme-alpine {
  width: 100%;
  height: 100%;
}
</style>
